import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: "16px"
};

export default function InterviewSchedule({ openModal, closeModal }) {

  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='px-10 pt-5' style={{ borderRadius: "16px 16px 0 0" }}>
            <h2 className='heading2 ' >
              Schedule Interview</h2>
          </div>
          <div className='modal-form px-10 py-5'>
            <form className='form-theme'>
              <div className='relative mb-4'>
                <input type='text' placeholder='Stage name' className='w-full border border-gray-300' />
              </div>
              <div className='grid grid-cols-2'>
                <div className='relative mb-4 mr-3'>
                  <label>Current Stage</label>
                  <select
                    className="w-full border border-gray-300">
                    <option value="">Select Stage</option>
                    <option value="1">In review</option>
                    <option value="2">Shortlisted</option>
                  </select>
                </div>
                <div className='relative mb-4 '>
                  <label>Candidates</label>
                  <select
                    className="w-full border border-gray-300">
                    <option value="">Candidates</option>
                    <option value="1">Shreyansh</option>
                  </select>
                </div>
                <div className='relative mb-4  mr-3'>
                  <label>Interview Stage</label>
                  <input type='text' placeholder='Stage name' className='w-full border border-gray-300' />
                </div>
                <div className='relative mb-4 mr-3'>
                  <label>Interviewer</label>
                  <select
                    className="w-full border border-gray-300">
                    <option value="">Interviewer</option>
                    <option value="1">Shreyansh Singh</option>
                  </select>
                </div>
                <div className='relative mb-4  mr-3'>
                  <label>Interview Date</label>
                  <select
                    className="w-full border border-gray-300">
                    <option value="">Interview Date</option>
                    <option value="Full-time">Full-time</option>
                  </select>
                </div>
                <div className='relative mb-4'>
                  <label>Interview time</label>
                  <select
                    className="w-full border border-gray-300">
                    <option value="">Pick a slot</option>
                    <option value="1">11am 12pm</option>
                    <option value="2">12pm 1pm</option>
                    <option value="3">2pm 3pm</option>
                  </select>
                </div>
              </div>
              <div className='my-4'>
                <button type='button' className='py-2 px-3 mr-3 rounded-full bg-[#C4C4C4] text-sm text-white inline-flex items-center'>
                  <img src='images/icons/video.svg' alt='video' className='mr-2'/>Video call</button>
                <button type='button'className='py-2 px-3 mr-3 rounded-full bg-[#C4C4C4] text-sm text-white inline-flex items-center'>
                <img src='images/icons/phone-light.svg' alt='phone' className='mr-2'/>Phone </button>
                <button type='button'className='py-2 px-3 mr-3 rounded-full bg-[#C4C4C4] text-sm text-white inline-flex items-center'>
                <img src='images/icons/company-light.svg' alt='company' className='mr-2'/>In-office </button>
              </div>
              <div className='relative mb-4'>
                <label>Joining Link</label>
                <input type='text' placeholder='https://meet.google.com/otd-dckc-hkb' className='w-full border border-gray-300' />
              </div>
            <div className='flex justify-end'>
            <button type="button" className="theme-btn bordered-btn py-2 mr-3" onClick={closeModal}>
                <span className="color-text">Cancel</span>
              </button>
              <button type="button" className="theme-btn bordered-btn py-2">
                <span className="color-text">Save</span>
              </button>
            </div>
            </form>

          </div>
        </Box>
      </Modal>
    </div>
  );
}
