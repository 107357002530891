import React from 'react'

export default function Hiring() {
    return (
        <div>
            <div className='hiring-stages'>
                <div className='pointer light'>Screening</div>
                <div className='pointer light'>Test </div>
                <div className='pointer light'>Interview</div>
                <div className='pointer light'>HR Round</div>
                <div className='pointer light'>Hired</div>
            </div>
            <div className="design-box mt-3">
                <div className="heading">
                    <h2>Detail </h2>
                </div>
                <div className="box-content">
                    <div className="grid grid-cols-2 gap-2">
                        <div className="box-details">
                            <div className="mb-2">
                                <label>Current Stage </label>
                                <span>Screening</span>
                            </div>
                            <div className="mb-2">
                                <label>Date </label>
                                <span>March 20, 2023</span>
                            </div>
                        </div>
                        <div className="box-details">
                            <div className="mb-2">
                                <label>Assigned to</label>
                                <div className='multiImg mt-5'>
                                    <img src='images/man.svg' className='left-7' />
                                    <img src='images/girl.svg' className='left-0' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end'>
                        <button type="button" className="theme-btn bordered-btn"><span className='color-text'>Move to Next Round</span></button>
                        <button type='button' className='p-2 border-red-500 rounded-full w-32 text-red-600 ml-2' style={{ border: "1px solid red" }}> Reject</button>
                    </div>
                </div>
            </div>
            <div className='h-12 mt-3'>
                <button type="button" className="color-btn"><img src="images/icons/plus.svg" className="inline" />Add Feedback</button>
            </div>
            <div className="interview flex justify-between items-center mt-2 ">
                <div className="flex items-center">
                    <img src="images/man.svg" className="mr-3" />
                    <div>
                        <h3>Katha Menon</h3>
                        <h5>Technical Round 1</h5>
                    </div>
                </div>
                <div>
                    <h5>10 July, 2021</h5>
                </div>
            </div>
        </div>
    )
}
