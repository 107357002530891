import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { colors } from '@mui/material';
const cards = [
    {
        designation: "Sr. UI/UX Designer",
        img: "images/slack.svg",
        status:"In-Progress",
        color:"#516AF4"
    },
    {
        designation: " UI/UX Designer",
        img: "images/w.svg",
        status:"Shortlisted",
        color:"#FFC107"
    },

    {
        designation: "Sr. UI/UX Designer",
        img: "images/slack.svg",
        status:"Rejected",
        color:"#FF2D10"
    },


]
export default function ApplicationContent() {
    return (
        <>
            <div>
                <div className='default-cards'>
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4'>
                        {cards.map((item) => (
                            <div className='card1 mb-3'>
                                <div className='card-data'>
                                    <div className='mb-4 flex'>
                                        <button type='button' className='px-3 py-2 text-sm rounded-full text-white' 
                                        style={{backgroundColor:item.color}}>
                                          {item.status}
                                        </button>
                                        <MoreVertIcon className='ml-auto'/>
                                    </div>
                                    <div className='flex items-center'>
                                        <img src={item.img} className='mr-2'/>
                                        <div>
                                            <h4>{item.designation}</h4>
                                            <p>Slack Mumbai , India  , 3 lakh CTC</p>
                                        </div>
                                    </div>
                                    <div className="my-3 type-btns flex items-center">
                                        <button><img src="images/icons/location.svg" />Hybrid</button>
                                        <button><img src="images/icons/cap.svg" />3+ yrs exp.</button>
                                        <button><img src="images/icons/clock.svg" />Full-time</button>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </div>
        </>
    );
}
