import React from 'react';
import { useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { toggleDrawer } from '../../redux copy/actionTypes';


const DrawerHeaderStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function DrawerHeader() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleDrawerClose = () => {
    dispatch(toggleDrawer(false));
  };

  return (
    <DrawerHeaderStyled>
      <h1 className='logo-side mr-auto pt-2 ps-2'>
        <span className="color-text">Hirree</span>
      </h1>
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </DrawerHeaderStyled>
  );
}
