import React from "react";
import { Link } from "react-router-dom";
import config from "../../config/config";

const LeftImage = () => {
    return (
        <>
            <div className="px-5 py-10 img-left">
                <div className="py-5">
                    <h1 className="logo text-left" style={{textAlign:"left"}}>
                       <Link to={`${config.baseUrl}`}><span className="color-text text-lg">Hirree</span></Link> 
                    </h1>
                    {/* <div className="box pt-10">
                        <div className="flex">
                            <div className="owel-box owel-box1 ">
                                <img src="images/man.png"/>
                            </div>
                            <div className="owel-box owel-box2 ">
                            <img src="images/women.svg"/>
                            </div>
                        </div>
                    </div> */}
                    <img src="images/side-img.png" className="login-bg"/>
                </div>

            </div>
        </>
    )
}
export default LeftImage