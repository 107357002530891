import { SET_PARSED_CV_DATA } from "../actionTypes";

const initialState = {
   parseCvData: null
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARSED_CV_DATA:
      return {
        ...state,
        parseCvData: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
  