import React, { useEffect } from "react";
import Header from "../../directories/header";
import Footer from "../../directories/footer";
import data from "./privacyData";

const PrivacyPolicy = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    return (
        <>
            <Header />
            <section className="pt-0">
                <div className="bg-text">
                    <h1 className="text-center text-2xl font-bold">
                        <span className="text-primary">{data.title}</span>
                    </h1>
                </div>
                <div className="container mx-auto px-4">
                    <div className="pt-3 md:pt-5 flex justify-center">
                        <div className="w-full md:w-10/12">
                            <div className="content-text">
                                {data.description.map((desc, index) => (
                                    <p key={index} className="mb-4">{desc}</p>
                                ))}

                                {data.points.map((point, pointIndex) => (
                                    <div key={pointIndex} className="mb-6">
                                        <h3 className="text-lg font-semibold">{point.title}</h3>
                                        {point.data.map((dataItem, dataIndex) => (
                                            <div key={dataIndex} className="ml-6">
                                                {dataItem.description.map((descItem, descIndex) => (
                                                    <p key={descIndex} className="mb-2">{descItem}</p>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
