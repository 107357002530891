import React, { useState } from "react";
import config from "../../config/config";
import * as Yup from 'yup';
import axios from "axios";
import { Toaster, resolveValue, toast } from 'react-hot-toast';
import { Link } from "react-router-dom";
import SignupFooter from "./signupFooter";
import { useDispatch } from "react-redux";
import { Auth } from "../../redux copy/actionTypes";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';


const EmployerSignup = () => {
  const [form, setForm] = useState({ "company_email": "", "name": "", "password": "", "confirm_password": "", "phone_number": "", "company_name": "" });
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false) 

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const toggleConfirmPassword = () => {
    setshowConfirmPassword((prevState) => !prevState);
  }

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required')
      .required('Password is required')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(
      /[@$!%*#?&]/,
      "Password must contain at least one special character"
    ),

    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),

    phone_number: Yup.string()
      .required('Phone number is required')
      .min(10, 'Phone Number must have 10 digits'),

    company_email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),

    company_name: Yup.string().required('Name is required'),

    name: Yup.string()
      .required('Name is required')
  });


  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(form);
      setLoading(true)
      const config1 = {
        method: 'post',
        url: `${config.apiUrl}employer/signup`,
        data: form
      };

      const response = await axios(config1);

      if (response.status === 201) {
        toast.success("Registered successfully");
        setShowOtpInput(true)
        console.log("Success:", response.data);

      } else {
        toast.error("Something went wrong");
      }


    } catch (error) {

      if (error.response) {

        console.error("API Error:", error.response.data);
        toast.error(error.response.data.detail);
      }
      else if (error.request) {
        console.error("Network Error:", error.request);
        toast.error("Network error occurred");
      }
      else {
        console.error("Validation Error:", error.message);
        toast.error(error.message);
      }
    }
    finally{
      setLoading(false)
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${config.apiUrl}employer/verify-otp`, { otp, company_email: form.company_email });

      if (response.status === 200) {
        toast.success(response.data.message);
        dispatch({
          type: Auth,
          payload: response.data
        })
        window.location.href = `${config.employerUrl}dashboard`;

      } else {
        toast.error("Failed to verify OTP");
      }

    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.data);
        toast.error(error.response.data.detail);
      }
      else if (error.request) {
        console.error("Network Error:", error.request);
        toast.error("Network error occurred");
      }
      else {
        console.error("Validation Error:", error.message);
        toast.error(error.message);
      }
    }
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setForm((old) => ({ ...old, [name]: value }));
  };

  return (
    <>
      <Toaster />
      <div className="center-item">
        {!showOtpInput ?
          (<form className="space-y-4 " onSubmit={handleSignup}>
            <div>
              <label className="block">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={form.name}
                onChange={inputHandler}
                className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                placeholder="Enter your name"
              />
            </div>
            <div>
              <label className="block">Company Name</label>
              <input
                type="text"
                name="company_name"
                value={form.company_name}
                onChange={inputHandler}
                className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                placeholder="Enter your name"
              />
            </div>
            <div>
              <label className="block">Company Email address</label>
              <input
                type="email"
                name="company_email"
                value={form.company_email}
                onChange={inputHandler}
                className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                placeholder="Enter your email"
              />
            </div>
            <div>
              <label className="block">Phone number</label>
              <input
                type="text"
                name="phone_number"
                value={form.phone_number}
                onChange={inputHandler}
                className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                placeholder="Enter your phone number"
              />
            </div>
            <div>
              <label className="block">Password</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={form.password}
                  onChange={inputHandler}
                  className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                  placeholder="Enter your password"
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-3 cursor-pointer"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
            </div>
            <div>
              <label className="block">Confirm Password</label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  value={form.confirm_password}
                  onChange={inputHandler}
                  className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                  placeholder="Confirm password"
                />    <span
                  onClick={toggleConfirmPassword}
                  className="absolute right-3 top-3 cursor-pointer"
                >
                  {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
            </div>
            {/* <div className="flex items-center mb-4">
            <input id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ms-2 dark:text-gray-300">Remember me</label>
          </div> */}
            <div>
              <button type="submit" className="theme-btn mr-2 text-white">Sign up
              {loading == true ?
                  <CircularProgress
                    size={25}
                    disableShrink
                    style={{ color: "#fff", margin: "0 10px" }} />
                  : null}
              </button>
            </div>
          </form>)
          : (
            <form className="space-y-4 mt-4" onSubmit={handleOtpSubmit}>
              <div>
                <label className="block">Enter OTP</label>
                <input
                  type="text"
                  name="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                  placeholder="Enter the OTP"
                />
              </div>
              <div>
                <button type="submit" className="theme-btn mr-2 text-white">Submit OTP</button>
              </div>
            </form>
          )}
        <SignupFooter />

      </div>
    </>
  );
};

export default EmployerSignup;
