import React from 'react';
import RightDrawer from './rightDrawer';

const cards = [
    {
        designation: "Sr. UI/UX Designer",
        img: "images/slack.svg"
    },
    {
        designation: " UI/UX Designer",
        img: "images/w.svg"
    },

    {
        designation: "Sr. UI/UX Designer",
        img: "images/slack.svg"
    },
    {
        designation: " UI/UX Designer",
        img: "images/w.svg"
    },
    {
        designation: "Sr. UI/UX Designer",
        img: "images/slack.svg"
    },
    {
        designation: "Sr. UI/UX Designer",
        img: "images/slack.svg"
    },

]
export default function RecomendationContent() {
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };
    return (
        <>
            <div>
            <RightDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer} />
                <h2 className='menu-heading pl-0 lg:mb-4'>Recommendations</h2>
                <div className='default-cards'>
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4'>
                        {cards.map((item) => (
                            <div className='card1 mb-3'>
                                <div className=' card-data '>
                                    <div className='flex items-center justify-between'>
                                        <img src={item.img} />
                                        <div>
                                            <h4>{item.designation}</h4>
                                            <p>Slack Mumbai , India  , 3 lakh CTC</p>
                                        </div>
                                            <button className='md:p-1 lg:p-2  flex items-center justify-center lg:h-10 lg:w-10 lg:h-8 lg:w-8 bg-[#E7E7E7] rounded-full'
                                                onClick={toggleDrawer(true)}>   
                                                 <img src='images/icons/send-arrow.svg' className='brand-icon' />
                                            </button>
                                    </div>
                                    <div className="my-3 type-btns flex items-center">
                                        <button><img src="images/icons/location.svg" />Hybrid</button>
                                        <button><img src="images/icons/cap.svg" />3+ yrs exp.</button>
                                        <button><img src="images/icons/clock.svg" />Full-time</button>
                                    </div>
                                    <div className="flex justify-between ">
                                        <span><img src='images/icons/history.svg' className=' md:mr-1 lg:mr-2 inline-block' />Posted 2 days ago</span>
                                        <span>Np : 2 Months</span>
                                    </div>
                                </div>
                                <div className='foot-content  items-center flex px-6 py-3'>
                                    <button type='button' className='theme-btn text-white text-sm'>Apply Now</button>
                                    <div className='ml-auto'>
                                        <button type='button'><img src='images/icons/share.svg' className='mr-4' /></button>
                                        <button type='button'><img src='images/icons/save.svg' /></button>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </div>
        </>
    );
}
