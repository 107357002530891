import React from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DrawerHeader from './DrawerHeader';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import { useDispatch } from 'react-redux';

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    height:"100%",
    zIndex:0,
    
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const menuList = [
  {
    name:"Dashboard",
    icon:'images/icons/home.svg',
    link:"dashboard"
  },
  {
    name:"Find Job",
    icon:"images/icons/job.svg",
    link:""
  },
  {
    name:"Recommendations",
    icon:"images/icons/applicants.svg",
    link:"recomendation"
  },
  {
    name:"My Applications",
    icon:"images/icons/calender.svg",
    link:"my-applications"
  },
  {
    name:"Saved Jobs",
    icon:"images/icons/calender.svg",
    link:"saved-jobs"
  },
  {
    name:"Companies",
    icon:"images/icons/company.svg",
    link:""
  },
  {
    name:"Interviews",
    icon:"images/icons/calender.svg",
    link:""
  }
]
const menuList2 = [
  {
    name:"Settings",
    icon:'images/icons/setting.svg',
    link:"setting"
  },
  {
    name:"Help centre",
    icon:"images/icons/help.svg",
    link:""
  }
]
export default function Drawer() {
  const theme = useTheme();
  const open = useSelector((state) => state.auth.drawerOpen);
  const dispatch = useDispatch()
  const logout = () =>{
    dispatch({
      type: 'LOGOUT', 
  });

  localStorage.removeItem('formData');
    window.location.href = `${config.baseUrl}login`;
  }
  return (
    <DrawerStyled variant="permanent" open={open} >
      <DrawerHeader />
      <List>
        {menuList.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
            component={Link}
             to={`${config.candidateUrl}${item.link}`}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
               <img src={item.icon}/>
              </ListItemIcon>
              <ListItemText  className='menu-name'  sx={{ opacity: open ? 1 : 0 }}>{item.name}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List style={{top:"5%"}}>
        {menuList2.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
            component={Link}
            to={`${config.candidateUrl}${item.link}`}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
               <img src={item.icon}/>
              </ListItemIcon>
              <ListItemText  className='menu-name'  sx={{ opacity: open ? 1 : 0 }}>{item.name}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>


      <List style={{top:"10%"}}>
          <ListItem  disablePadding sx={{ display: 'block' }}>
            <ListItemButton
            onClick={logout}
            component={Link}
            //  to={`${config.employerUrl}${item.link}`}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
               <img src="images/icons/logout.svg"/>
              </ListItemIcon>
              <ListItemText  className='menu-name'  sx={{ opacity: open ? 1 : 0 }}>Log-out</ListItemText>
            </ListItemButton>
          </ListItem>
      </List>
    </DrawerStyled>
  );
}
