import React, { useState } from "react";
import config from "../../../config/config";
import * as Yup from 'yup';
import axios from "axios";
import { Toaster, resolveValue, toast } from 'react-hot-toast';
import { Link } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { Auth } from "../../../redux copy/actionTypes";

const WithOTP = ({ switchToPassword }) => {
  const [company_email, setCompany_email] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState();
  const loginData = useSelector((state) => state.auth.Auth);

  const dispatch = useDispatch()

  const validationSchema = Yup.object({
    company_email: Yup.string().required('Email is required'),
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate({company_email});

      const response = await axios.post(`${config.apiUrl}employer/login/otp`,{company_email:company_email});

      if (response.status === 200) {
        console.log("Success:", response.data);
        toast.success("Login Successfully")
        setShowOtpInput(true)
      } else {
        toast.error("Something went wrong");
      }


    } catch (error) {
      if (error.response) {
        // Server-side error
        console.error("API Error:", error.response.data);
        toast.error(error.response.data.detail || "API error occurred");
      } else if (error.request) {
        // Network error
        console.error("Network Error:", error.request);
        toast.error("Network error occurred");
      } else {
        // Validation error or other client-side error
        console.error("Validation Error:", error.message);
        toast.error(error.message);
      }
    
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${config.apiUrl}employer/login/otp/verify`,{otp: otp, company_email: company_email });

      if (response.status === 200) {
        console.log("Success:", response.data);
        toast.success("Login Successfully")
        dispatch({
          type:Auth,
          payload:response.data
          })
          if (loginData.access_token) {
            window.location.href = `${config.employerUrl}dashboard`;
          } else {
            throw new Error("Access token not found!");
          }
      } else {
        toast.error("Something went wrong");
      }


    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.data);
        toast.error(error.response.data.message || "API error occurred");
      } 
      else if (error.request) {
        console.error("Network Error:", error.request);
        toast.error("Network error occurred");
      } 
      else {
        console.error("Validation Error:", error.message);
        toast.error(error.message);
      }
    }
  };


  return (
    <>
      <Toaster />
      <div className="">
      {!showOtpInput ? 
        <form className="space-y-4 " onSubmit={handleLogin}>
          <div>
            <label className="block">Email</label>
            <input
              type="text"
              id="email"
              name="company_email"
              value={company_email}
              onChange={(e)=>setCompany_email(e.target.value)}
              className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
              placeholder="Enter your name"
            />
          </div>
          <div className="flex items-center justify-between mb-4">
            {/* <div>
            <input id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ms-2 dark:text-gray-300">Remember me</label>
            </div> */}
            <div>
            <Link to="#" onClick={switchToPassword}>Login with Password</Link>
            </div>
          </div>
          <div>
            <button type="submit" className="theme-btn mr-2 text-white w-full">Send OTP</button>
          </div>
        </form>
       :
          <form className="space-y-4 mt-4 px-5" onSubmit={handleOtpSubmit}>
            <div>
              <label className="block" htmlFor="otp">Enter OTP</label>
              <input
                type="text"
                 id="otp"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                placeholder="Enter the OTP"
              />
            </div>
            <div>
              <button type="submit" className="theme-btn mr-2 text-white">Continue</button>
            </div>
          </form>
        }
      </div>
    </>
  );
};

export default WithOTP;
