import React, { useState } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import DrawerHeader from "../dashboard/DrawerHeader";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InterviewSchedule from "../interviewSchedule";



function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="gradientColors" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" stopColor="rgba(26,217,223,1)" />
                        <stop offset="100%" stopColor="rgba(168,36,254,1)" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress
                variant="determinate"
                {...props}
                size={70}
                thickness={3.5}
                sx={{
                    '& .MuiCircularProgress-circle': {
                        stroke: 'url(#gradientColors)',
                    },
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    sx={{
                        fontSize: '18px',
                    }}
                >
                    <span className="color-text font-bold">{`${Math.round(props.value)}%`}</span>
                </Typography>
            </Box>
        </Box>

    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const TotalApplicantsCards = () => {
    const open = useSelector((state) => state.auth.drawerOpen);
    console.log("dsaaaaaaaa", open)


    const [progress, setProgress] = React.useState(90);
    const [selectJob, setSelectJob] = React.useState('');
    const [selectByStatus, setSelectByStatus] = React.useState('');
    const [openMenu, setOpenMenu] = useState(false)
    const [openModal,setOpenModal] = useState(true)
    const OpenInterviewModal =()=> {
        setOpenModal(true)
    }
    const handleCoseModal =()=> {
        setOpenModal(false)
    }
    const handleMenu = () => {
        setOpenMenu(!openMenu)
    }

    const handleChangeJob = (event) => {
        setSelectJob(event.target.value);
    };
    const handleChangeStatus = (event) => {
        setSelectByStatus(event.target.value);
    };

    const applicants = [
        {}, {}, {}, {}, {}
    ]

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent">
                <DrawerHeader />
                <div className="flex items-center mt-4">
                    <img src="images/icons/arrowLeft.svg" />
                    <div>
                        <h2 className="menu-heading py-0">UI/UX Designer</h2>
                        <h2 className="sub-heading ml-5"><span>UI/UX Designer</span>
                            <span>Full-Time</span>
                            <span>2-4 yrs Exp.</span>
                        </h2>
                    </div>
                </div>
                <div className="filters flex justify-between items-center mt-4">
                    <div className="md:ml-5">
                        <h2 className="heading2">Total Applicants : 32</h2>
                    </div>
                    <div className="header-menus">
                        <button type="button" className="theme-btn bordered-btn h-12" onClick={OpenInterviewModal}>Schedule Interview<img src="images/icons/schedule.svg"/></button>
                        <button type="button" className="theme-btn bordered-btn h-12 mx-2 ">Bulk Move <img src="images/icons/arrowRight.svg" /></button>
                        <input type="text" placeholder="Search" className=" h-12 mx-2 "/>
                    </div>
                </div>
                <InterviewSchedule openModal={openModal} closeModal={handleCoseModal}/>

                <div className="p-4 total-applicants">
                    <div className="scrollable-section">
                        <div className="job-section hold">
                            <div className="section-name mb-3">
                                <div className="dot"></div>
                                <span>In Review</span>
                                <div className="ml-auto flex items-center">
                                    <input type="checkbox" className="checkbox mr-2" />
                                    <label>Select All</label>
                                </div>
                                
                            </div>
                            <div>
                                {applicants.map(item => (
                                    <div className="applicants-card pr-8">
                                        <div className="flex justify-between items-center">
                                            <input type="checkbox" className="checkbox" />
                                            <div className="user-img"><img src="images/man1.png" alt="user" /></div>
                                            <div className="user-detail ms-2">
                                                <h3>Annette Black</h3>
                                                <span>Applied on 13/07/2024</span>
                                                <span><Link to="">View Profile</Link></span>
                                            </div>
                                            <div>
                                                <p>Current CTC : <b>3 LPA</b> </p>
                                                <p>NP : <b>Immediate</b> </p>
                                            </div>
                                            <div>
                                                <CircularProgressWithLabel value={progress} />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="job-section opening">
                            <div className="section-name mb-3">
                                <div className="dot"></div>
                                <span>Shortlisted</span>
                                <div className="ml-auto flex items-center">
                                    <input type="checkbox" className="checkbox mr-2" />
                                    <label>Select All</label>
                                </div>
                                

                            </div>
                            <div>
                                <div className="applicants-card pr-8">
                                    <div className="flex justify-between items-center">
                                        <input type="checkbox" className="checkbox" />
                                        <div className="user-img"><img src="images/man1.png" alt="user" /></div>
                                        <div className="user-detail ms-2">
                                            <h3>Annette Black</h3>
                                            <span>Applied on 13/07/2024</span>
                                            <span><Link to="">View Profile</Link></span>
                                        </div>
                                        <div>
                                            <p>Current CTC : <b>3 LPA</b> </p>
                                            <p>NP : <b>Immediate</b> </p>
                                        </div>
                                        <div>
                                            <CircularProgressWithLabel value={progress} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="job-section green">
                            <div className="section-name mb-3">
                                <div className="dot"></div>
                                <span>Hired</span>
                                <div className="ml-auto flex items-center">
                                    <input type="checkbox" className="checkbox mr-2" />
                                    <label>Select All</label>
                                </div>
                            </div>
                            <div>
                                <div className="applicants-card pr-8">
                                    <div className="flex justify-between items-center">
                                        <input type="checkbox" className="checkbox" />
                                        <div className="user-img"><img src="images/man1.png" alt="user" /></div>
                                        <div className="user-detail ms-2">
                                            <h3>Annette Black</h3>
                                            <span>Applied on 13/07/2024</span>
                                            <span><Link to="">View Profile</Link></span>
                                        </div>
                                        <div>
                                            <p>Current CTC : <b>3 LPA</b> </p>
                                            <p>NP : <b>Immediate</b> </p>
                                        </div>
                                        <div>
                                            <CircularProgressWithLabel value={progress} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="job-section reject">
                            <div className="section-name mb-3">
                                <div className="dot"></div>
                                <span>Rejected</span>
                                <div className="ml-auto flex items-center">
                                    <input type="checkbox" className="checkbox mr-2" />
                                    <label>Select All</label>
                                </div>
                            </div>
                            <div>
                                <div className="applicants-card pr-8">
                                    <div className="flex justify-between items-center">
                                        <input type="checkbox" className="checkbox" />
                                        <div className="user-img"><img src="images/man1.png" alt="user" /></div>
                                        <div className="user-detail ms-2">
                                            <h3>Annette Black</h3>
                                            <span>Applied on 13/07/2024</span>
                                            <span><Link to="">View Profile</Link></span>
                                        </div>
                                        <div>
                                            <p>Current CTC : <b>3 LPA</b> </p>
                                            <p>NP : <b>Immediate</b> </p>
                                        </div>
                                        <div>
                                            <CircularProgressWithLabel value={progress} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Box>
        </>
    )
}
export default TotalApplicantsCards;
