import React, { useState } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import DrawerHeader from "../dashboard/DrawerHeader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import RightDrawer from "./ApplicantsDetail";





const DataTable = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);


    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [selectJob, setSelectJob] = React.useState('');
    const [selectByStatus, setSelectByStatus] = React.useState('');

    const handleChangeJob = (event) => {
        setSelectJob(event.target.value);
    };
    const handleChangeStatus = (event) => {
        setSelectByStatus(event.target.value);
    };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };
    const Applicants = () => {
        return (
            <>
                <div className="app-name flex items-center">
                    <img src="images/man1.png" className="mr-2" />
                    <h3 className="mb-0">Charlie Kristen</h3>
                </div>
            </>
        )
    }
    const columns = [
        { id: 'applicants', label: 'Applicant', minWidth: 200 },
        { id: 'rating', label: 'RATING', minWidth: 100 },
        {
            id: 'stages',
            label: 'STAGES',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'role',
            label: 'APPLIED ROLE',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'date',
            label: ' APPLIED ON',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'details',
            label: 'See Details',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
    
    ];
    
    function createData(applicants, rating, stages, role, date, details) {
        return { applicants, rating, stages, role, date, details };
    }
    
    const rows = [
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 1324171354, 3287263, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 1403500365, 9596961, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 60483973, 301340, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 327167434, 9833520, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 37602103, 9984670, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 25475400, 7692024, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 83019200, 357578, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 4857000, 70273, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 126577691, 1972550, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 126317000, 377973, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 67022000, 640679, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 67545757, 242495, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 146793744, 17098246, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 200962417, 923768, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
        createData(<Applicants />, <><StarRateRoundedIcon sx={{ color: "#FFD928" }} />  4.5</>, 210147125, 8515767, '12/02/23', <MoreHorizIcon onClick={toggleDrawer(true)} />),
    ];
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent">
                <DrawerHeader />
                <RightDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer} />
                <div>
                    <h2 className="menu-heading">All Applicants</h2>
                </div>
                {/* /////////////////////filters//////////////////// */}
                <div className="filters flex justify-end mb-4">
                    <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                        <InputLabel id="demo-select-small-label">Based on Job Listing</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectJob}
                            onChange={handleChangeJob}
                            label="Based on Job Listing"

                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>UI/UX</MenuItem>
                            <MenuItem value={20}>Front-end Developer</MenuItem>
                            <MenuItem value={30}>Backend Developer</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                        <InputLabel id="demo-select-small-label">Applicant Status</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectByStatus}
                            onChange={handleChangeStatus}
                            label="Applicant Status"

                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>In review</MenuItem>
                            <MenuItem value={20}>In Progress</MenuItem>
                            <MenuItem value={30}>Rejected</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 ,width:"150px"}}  size="small">
                    <TextField id="outlined-basic" label="Search" variant="outlined"  fullWidth size="small"/>
                    </FormControl>
                </div>
                <div>
                    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                        <TableContainer >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number'
                                                                    ? column.format(value)
                                                                    : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </Box>
        </>
    )
}
export default DataTable;
