import React, { useEffect, useState } from "react";
import config from "../../../config/config";
import * as Yup from 'yup';
import axios from "axios";
import { Toaster, resolveValue, toast } from 'react-hot-toast';
import { Link } from "react-router-dom";
import LoginFooter from "../loginFooter";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Auth } from "../../../redux copy/actionTypes"
import CircularProgress from '@mui/material/CircularProgress';


const WithPassword = ({ switchToOtp }) => {
  const [form, setForm] = useState({ "password": "", "email": "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required'),
    email: Yup.string().required('Email is required'),

  });
  // useEffect(()=>{
  //   fetchProfileData()
  // },[])
  // const fetchProfileData = async () => {
  //   try {
  //       const res = await axios.get(`${config.apiUrl}candidate/profile`, {
  //           headers: {
  //               'Authorization': `Bearer ${loginData.access_token}`,
  //           }
  //       }
  //     )
  //     if(res.data && res.data.length>0){
  //       setProfileExist(true)
  //     }
  //     }catch(error){console.log(error)}
  //   }

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(form);
      setLoading(true)
      const config1 = {
        method: 'post',
        url: `${config.apiUrl}candidate/login`,
        data: form,
      };

      const response = await axios(config1);

      if (response.status === 200) {
        console.log("Success:", response.data);
        toast.success("Login Successfully")
        dispatch({
          type: Auth,
          payload: response.data
        })
        if (response.data.cv_exists === true) {
          window.location.href = `${config.candidateUrl}setting`;
        }
        else {
          window.location.href = `${config.baseUrl}candidate/dashboard`;
        }
      } else {
        toast.error("Something went wrong");
      }


    } catch (error) {
      if (error.response) {
        // Server-side error
        console.error("API Error:", error.response.data);
        toast.error(error.response.data.detail || "API error occurred");
      } else if (error.request) {
        // Network error
        console.error("Network Error:", error.request);
        toast.error("Network error occurred");
      } else {
        // Validation error or other client-side error
        console.error("Validation Error:", error.message);
        toast.error(error.message);
      }

    }
    finally{
      setLoading(false)
    }
  };



  const inputHandler = (e) => {
    const { name, value } = e.target;
    setForm((old) => ({ ...old, [name]: value }));
  };

  return (
    <>
      <Toaster />
      <div className="">

        <form className="space-y-4" onSubmit={handleLogin}>
          <div>
            <label className="block">Email</label>
            <input
              type="text"
              id="email"
              name="email"
              value={form.email}
              onChange={inputHandler}
              className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
              placeholder="Enter your Email"
            />
          </div>
          <div>
            <label className="block">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={form.password}
                onChange={inputHandler}
                className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                placeholder="Enter your password"
              />
              <span
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-3 cursor-pointer"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-4">
            {/* <div>
            <input id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ms-2 dark:text-gray-300">Remember me</label>
            </div> */}
            <div>
              <Link to="#" onClick={switchToOtp}>Login with OTP</Link>
            </div>
          </div>
          <div>
            <button type="submit" className="theme-btn mr-2 text-white w-full">Continue
              {loading == true ?
                <CircularProgress
                  size={25}
                  disableShrink
                  style={{ color: "#fff", margin: "0 10px" }} />
                : null}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default WithPassword;
