import React, { useState } from 'react';
import CandidateSignUp from './CandidateForm';
import EmployerSignup from './EmployerSignup';
import LeftImage from '../design/LeftImage';
function Signup() {

  const [showComponent, setshowComponent] = useState('candidate')
  const [activeBtn, setActiveBtn] = useState('candidate')

  const handleCandidate = () => {
    setshowComponent('candidate')
    setActiveBtn('candidate')
  }

  const handleEmployer = () => {
    setshowComponent('employer')
    setActiveBtn('employer')
  }

  return (
    <div className='bg-[#F3F9FC] forms'>
      <div className="mx-auto sm:max-w-full sm:max-w-[1200px]  signup">
        <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1  gap-4">
          <div className="">
            <LeftImage />
          </div>
          <div className="bg-white p-5 py-5 ">
            <div className='mx-1 p-3'>
              <div className='flex justify-center items-center'>
                <button type="button" className={`me-2 bordered-btn theme-btn ${activeBtn === 'candidate' ? 'active' : ''}`} onClick={handleCandidate}>Job Seeker</button>
                <button type="button" className={`me-2 bordered-btn theme-btn ${activeBtn === 'employer' ? 'active' : ''}`} onClick={handleEmployer}>Company</button>
              </div>
              <div className='py-1'>
                <h1>Declutter your inbox.<br />
                  Organize your hiring.</h1>
              </div>
            </div>
            {showComponent === 'employer' && <EmployerSignup />}
            {showComponent === 'candidate' && <CandidateSignUp />}
          </div>
        </div>

      </div>
    </div>
  );
}

export default Signup;
