// RightDrawer.js
import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import JobDetails from './jobDetails';

const RightDrawer = ({ open, toggleDrawer }) => {

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      className='candidate-details-section'
    
    >
        <JobDetails/>
    </Drawer>
  );
};

export default RightDrawer;
