import React, { useState } from 'react';
import CandidateLogin from './candidate/CandidateLogin';
import EmployerLogin from './employer/EmployerLogin';
import LeftImage from '../design/LeftImage';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import config from '../../config/config';
function Login() {

  const [showComponent, setshowComponent] = useState('candidate')
  const[activeBtn,setActiveBtn] = useState('candidate')


  const handleCandidate = () => {
    setshowComponent('candidate')
    setActiveBtn('candidate')
  }

  const handleEmployer = () => {
    setshowComponent('employer')
    setActiveBtn('employer')
  }
  const loginData = useSelector((state) => state.auth.Auth);

  const userType = useSelector(state =>state.auth.Auth)
  if (loginData.access_token) {
    if (userType.role === 'candidate' ) {
      return <Navigate to={`${config.baseUrl}candidate/dashboard`} />;
    } else if (userType.role === 'employer') {
      return <Navigate to={`${config.baseUrl}employer/dashboard`} />;
    }
  }
  return (
    <div className='bg-[#F3F9FC] forms '>
    <div className="mx-auto sm:max-w-full sm:max-w-[1200px]  signup">
      <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1  gap-4">
        <div className="">
      <LeftImage/>
        </div>
        <div className="bg-white p-md-5 pt-28 px-20">
          <div className='mx-4 p-md-5 '>
            <div className='flex justify-center items-center'>
              <button type="button" className={`me-2 bordered-btn theme-btn ${activeBtn === 'candidate' ? 'active' : ''}`} onClick={handleCandidate}>Job Seeker</button>
              <button type="button" className={`me-2 bordered-btn theme-btn ${activeBtn === 'employer' ? 'active' : ''}`} onClick={handleEmployer}>Company</button>
            </div>
            <div className='py-3'>
              <h1>Welcome Back</h1>
            </div>
          </div>
          {showComponent === 'employer' && <EmployerLogin />}
          {showComponent === 'candidate' && <CandidateLogin />}
        </div>
      </div>

    </div>
    </div>
  );
}

export default Login;
