import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import DrawerHeader from "../dashboard/DrawerHeader";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const JobSatatus = () => {
    const loginData = useSelector((state) => state);
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}employer/jobs`,
                    {
                        headers: {
                            'Authorization': `Bearer ${loginData.auth.Auth}`,
                        }
                    }
                );
                if (response.data.message === "Jobs fetched successfully") {
                    setJobs(response.data.jobs["Opened Jobs"]);
                    console.log(jobs)
                }
            } catch (error) {
                console.error("Error fetching jobs:", error);
            }
        };

        fetchJobs();
    }, []);

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent">
                <DrawerHeader />
                <div>
                    <h2 className="menu-heading">Job Listing</h2>
                </div>
                <div className="p-4">
                    <div className="grid gap-6 mb-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">

                        <div className="job-section opening">
                            <div className="section-name mb-3">
                                <div className="dot"></div>
                                <span>Opened Jobs</span>
                            </div>
                            {/* {jobs.map((job) => ( */}
                                <div >
                                    <div className="status-card mt-7">
                                <div className="job-card blue ">
                                    <button className="view-black">
                                        <Link to={`${config.employerUrl}total-applicants`}>View</Link>
                                    </button>
                                    <div className="p-5 mt-3">
                                        <h3>Sr. UX Designer</h3>
                                        <span>3 lakh CTC Mumbai , India</span>
                                        <div className="mt-4 type-btns flex">
                                            <button><img src="images/icons/location.svg" />Hybrid</button>
                                            <button className="mx-2"><img src="images/icons/cap.svg" />3+ yrs exp.</button>
                                            <button><img src="images/icons/clock.svg" />Full-time</button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex justify-between p-5">
                                        <span>Posted 2 days ago</span>
                                        <span>Np : 2 Months</span>
                                    </div>
                                </div>
                            </div>
                                </div>
                            {/* ))} */}
                        </div>
                        <div className="job-section hold">
                            <div className="section-name mb-3">
                                <div className="dot"></div>
                                <span>On Hold Jobs</span>
                            </div>
                            <div className="status-card mt-7">
                                <div className="job-card yellow ">
                                <button className="view-black">
                                        <Link to={`${config.employerUrl}total-applicants`}>View</Link>
                                    </button>
                                    <div className="p-5 mt-3">
                                        <h3>Sr. UX Designer</h3>
                                        <span>3 lakh CTC Mumbai , India</span>
                                        <div className="mt-4 type-btns flex">
                                            <button><img src="images/icons/location.svg" />Hybrid</button>
                                            <button className="mx-2"><img src="images/icons/cap.svg" />3+ yrs exp.</button>
                                            <button><img src="images/icons/clock.svg" />Full-time</button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex justify-between p-5">
                                        <span>Posted 2 days ago</span>
                                        <span>Np : 2 Months</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="job-section reject">
                            <div className="section-name mb-3">
                                <div className="dot"></div>
                                <span>Closed Jobs</span>
                            </div>
                            <div className="status-card mt-7">
                                <div className="job-card red ">
                                <button className="view-black">
                                        <Link to={`${config.employerUrl}total-applicants`}>View</Link>
                                    </button>
                                    <div className="p-5 mt-3">
                                        <h3>Sr. UX Designer</h3>
                                        <span>3 lakh CTC Mumbai , India</span>
                                        <div className="mt-4 type-btns flex">
                                            <button><img src="images/icons/location.svg" />Hybrid</button>
                                            <button className="mx-2"><img src="images/icons/cap.svg" />3+ yrs exp.</button>
                                            <button><img src="images/icons/clock.svg" />Full-time</button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex justify-between p-5">
                                        <span>Posted 2 days ago</span>
                                        <span>Np : 2 Months</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}
export default JobSatatus;
