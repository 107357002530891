import axiosClient from "../../config/axios.Config";

export const UploadProfileAPI = (payload, token) => {
  return axiosClient("candidate/upload_photo", payload, "POST", {
    Authorization: token,
  });
};
export const getLocation = () => {
  return axiosClient("candidate/indian-states", "", "GET");
};
export const getSkills = () => {
  return axiosClient("candidate/skills", "", "GET");
};
export const getCountryColde = () => {
  return axiosClient("candidate/country-codes", "", "GET");
};
export const getFieldStudy = () => {
  return axiosClient("candidate/fields-of-study", "", "GET");
};
