import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Toaster, toast } from 'react-hot-toast';
import { getCountryColde, getFieldStudy, getLocation } from "../../../../utils/candidate.api/candidate.api.services";
import { getSkills } from "../../../../utils/candidate.api/candidate.api.services";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Loader from "../../../loader";
import CloseIcon from '@mui/icons-material/Close';
import config from "../../../../config/config";
import { setProfileID } from "../../../../redux copy/actionTypes";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const MyProfile = ({onNext }) => {
    const loginData = useSelector((state) => state.auth.Auth);
    const parsedCvData = useSelector((state) => state.parsedCv.parseCvData.parsed_data);
    const CVDetails = useSelector((state) => state.parsedCv.parseCvData.filename);
    const [working, setWorking] = useState(false)
    const [location, setLocation] = useState([])
    const [countryCode, setCountryCode] = useState([])
    const [fieldStudy, setFieldStudy] = useState([])
    const [getskills, setGetSkills] = useState([])
    const [openLoader, setOpenLoader] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false);



    const [formData, setFormData] = useState({
        general_details: {
            full_name: '',
            date_of_birth: '',
            gender: '',
            phone: '',
            email: '',
            location: '',
            country_code: '+91'
        },
        education: [{
            college: '',
            degree: '',
            field_of_study: '',
            start_date: '',
            end_date: '',
            grade: '',
        }],
        work_experience: [{
            designation: '',
            company: '',
            location: '',
            start_date: '',
            end_date: '',
            description: '',
            currently_working: false,
        }],
        skills: [],
        portfolio: {
            portfolio_link: '',
            github_link: '',
            other_link: ''
        }
    });

    const [errors, setErrors] = useState({})
    const validationSchema = Yup.object().shape({
        general_details: Yup.object().shape({
            full_name: Yup.string().required('Full Name is required'),
            date_of_birth: Yup.string().matches(
                /^\d{4}-\d{2}-\d{2}$/,
                "Date must be in the format YYYY-MM-DD"
            ).required('DOB is required in form of YYYY-MM-DD'),
            gender: Yup.string().required("Gender is required"),
            phone: Yup.string().required("Phone is required").matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
            email: Yup.string().email('Invalid email').required("Email is required"),
            location: Yup.string().required("Location is required"),
            country_code: Yup.string().required("Please select the country code")
        }),
        education: Yup.array().of(
            Yup.object().shape({
                college: Yup.string().required('College name is required'),
                degree: Yup.string().required('Degree is required'),
                field_of_study: Yup.string().required('Field of study required'),
                start_date: Yup.string().matches(
                    /^\d{4}-\d{2}-\d{2}$/,
                    "Date must be in the format YYYY-MM-DD"
                ).required('DOB is required in form of YYYY-MM-DD').required('start date is required '),
                end_date: Yup.string().matches(
                    /^\d{4}-\d{2}-\d{2}$/,
                    "Date must be in the format YYYY-MM-DD"
                ).required('DOB is required in form of YYYY-MM-DD').required('end is required'),
                grade: Yup.string().required('Grads is required')
            })
        ),
        work_experience: Yup.array().of(
            Yup.object().shape({
                // designation: Yup.string().required("Designation is required"),
                // company: Yup.string().required("Company name is required"),
                // location: Yup.string().required("Location is required"),
                // start_date: Yup.string().required('start date is required in form of YYYY-MM-DD'),
                // end_date: Yup.string().required('end is required in form of YYYY-MM-DD'),
                // description: Yup.string(),
                // currently_working: Yup.boolean(),
                total_work_experience_months: Yup.string().required("Total work experience requierd")
            })
        ),
        skills: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string().required('Skill is required')
                })
            )
            .min(1, 'At least one skill must be selected'),
    });

    useEffect(() => {
        if (parsedCvData) {
            const phone = parsedCvData.phone || '';
            const formattedPhone = phone.length > 10 ? phone.slice(-10) : phone;
            setFormData({
                general_details: {
                    full_name: parsedCvData.name || '',
                    phone: formattedPhone || '',
                    email: parsedCvData.email || '',
                    location: '',
                    date_of_birth: '',
                    gender: '',
                    country_code: '+91'
                },
                education: parsedCvData.education && parsedCvData.education.length > 0 ?
                    parsedCvData.education.map(edu => ({
                        college: edu.institution || '',
                        degree: edu.degree || '',
                        field_of_study: '',
                        start_date: '',
                        end_date: '',
                        grade: edu.cgpa || ''
                    })) : [{ college: '', degree: '', field_of_study: '', start_date: '', end_date: '', grade: '' }],

                work_experience: parsedCvData.experience && parsedCvData.experience.length > 0 ?
                    parsedCvData.experience.map(work => ({
                        total_work_experience_months: '',
                        designation: work.title || '',
                        company: work.company || '',
                        location: work.location || '',
                        start_date: '',
                        end_date: '',
                        description: '',
                        currently_working: working,

                    })) : [{ total_work_experience_months: '', designation: '', company: '', location: '', start_date: '', end_date: '', description: '', currently_working: false }],

                skills: [],
                //parsedCvData.skills ? parsedCvData.skills.flatMap(skillCategory =>
                //     skillCategory.skills.map(skill => ({ name: skill }))
                // ) : [{ name: '' }],
                portfolio: {
                    portfolio_link: '',
                    github_link: '',
                    other_link: ''
                }
            });

        }
    }, [parsedCvData]);
    const fetchLocation = async () => {
        try {
            let response = await getLocation()
            setLocation(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const fetchCountryCode = async () => {
        try {
            let response = await getCountryColde()
            setCountryCode(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    const fetchFieldStudy = async () => {
        try {
            let response = await getFieldStudy()
            setFieldStudy(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchLocation()
        fetchCountryCode()
        fetchFieldStudy()
        // fetchProfileDetails()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "work_experience.total_work_experience_months.0") {

            const totalExperience = parseInt(value, 10);
            setFormData(prevData => {
                let updatedWorkExperience;
                if (totalExperience > 0) {
                    if (parsedCvData && parsedCvData.experience && parsedCvData.experience.length > 0) {
                        // Populate with parsed CV data if available
                        updatedWorkExperience = parsedCvData.experience.map(work => ({
                            total_work_experience_months: value,
                            designation: work.title || "",
                            company: work.company || "",
                            location: work.location || "",
                            start_date: work.start_date || "",
                            end_date: work.end_date || "",
                            description: work.description || '',
                            currently_working: work.currently_working || false,
                        }));
                    } else {
                        // Provide empty fields if parsed data is not available
                        updatedWorkExperience = [{
                            total_work_experience_months: value,
                            designation: '',
                            company: '',
                            location: '',
                            start_date: '',
                            end_date: '',
                            description: '',
                            currently_working: false,
                        }];
                    }
                } else {
                    // Set all fields to empty if total experience is 0
                    updatedWorkExperience = [{
                        total_work_experience_months: value,
                        designation: '',
                        company: '',
                        location: '',
                        start_date: '',
                        end_date: '',
                        description: '',
                        currently_working: false,
                    }];
                }
                return {
                    ...prevData,
                    work_experience: updatedWorkExperience,
                };
            });

        }
        else if (name === "skills") {
            setFormData(prevData => ({
                ...prevData,
                skills: value.map(skillName => ({ name: skillName }))
            }));
        }
        else {

            const [section, field, index] = name.split('.');


            if (index !== undefined) {
                setFormData(prevData => ({
                    ...prevData,
                    [section]: prevData[section].map((item, i) =>
                        i === parseInt(index) ? { ...item, [field]: value } : item
                    )
                }));
            } else {
                setFormData(prevData => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [field]: value
                    }
                }));
            }
        }
    };
    const handleAddEducation = () => {
        setFormData(prevData => ({
            ...prevData,
            education: [
                ...prevData.education,
                { college: '', degree: '', field_of_study: '', start_date: '', end_date: '', grade: '' }
            ]
        }));
    };
    const handleAddWorkExperience = () => {
        setFormData(prevData => ({
            ...prevData,
            work_experience: [
                ...prevData.work_experience,
                {
                    designation: '',
                    company: '',
                    location: '',
                    start_date: '',
                    end_date: '',
                    description: '',
                    currently_working: false,
                }
            ]
        }));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formattedData = formatForSubmission();
        try {
            await validationSchema.validate(formattedData, { abortEarly: false });
            const method = isUpdating ? 'PUT' : 'POST'; 
            const url = `${config.apiUrl}candidate/profile`; 
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginData.access_token}`
                },
                body: JSON.stringify(formattedData)
            });
    
            if ((method === 'POST' && response.status === 201) || (method === 'PUT' && response.status === 200)) {
                setOpenLoader(true);
                const data = await response.json();
                toast.success(data.message);
                console.log('Success:', data);
            }
        } 

        catch (error) {
            if (error.name === 'ValidationError') {
                const validationErrors = {};
                error.inner.forEach(err => {
                    validationErrors[err.path] = err.message;
                });
                setErrors(validationErrors);
                // toast.error("Empty fields")
            }
            else {
                console.error("Error response:", error.response ? error.response.data : error);
                if (error.response && error.response.data && Array.isArray(error.response.data.detail)) {
                    const validationErrors = {};
                    error.response.data.detail.forEach(err => {
                        const field = err.loc[1];
                        validationErrors[field] = err.msg;
                    });
                    setErrors(validationErrors);
                }
            }


        }
        finally {
            setOpenLoader(false)
        }
    };
    // Function to format data for API submission
    const formatForSubmission = () => {
        return {
            general_details: {
                full_name: formData.general_details.full_name,
                date_of_birth: formData.general_details.date_of_birth || '',
                gender: formData.general_details.gender || '',
                phone: formData.general_details.phone || '',
                email: formData.general_details.email || '',
                location: formData.general_details.location || '',
                country_code: formData.general_details.country_code || ''
            },
            education: formData.education.map(edu => ({
                college: edu.college || '',
                degree: edu.degree || '',
                field_of_study: edu.field_of_study || '',
                start_date: edu.start_date || '',
                end_date: edu.end_date || '',
                grade: edu.grade || ''
            })),
            work_experience: formData.work_experience.map(work => ({
                total_work_experience_months: work.total_work_experience_months || '',
                designation: work.designation || '',
                company: work.company || '',
                location: work.location || '',
                start_date: work.start_date || '',
                end_date: work.end_date || '',
                description: work.description || '',
                currently_working: work.currently_working
            })),
            skills: formData.skills.map(skill => ({
                name: skill.name || ''
            })),
            portfolio: {
                portfolio_link: formData.portfolio.portfolio_link || '',
                github_link: formData.portfolio.github_link || '',
                other_link: formData.portfolio.other_link || ''
            }
        };
    };

    useEffect(() => {
        const fetchSkills = async () => {
            try {
                let response = await getSkills();
                const fetchedSkills = response.data.map(skill => skill.toLowerCase()); // Convert fetched skills to lowercase
                setGetSkills(fetchedSkills);
                const formattedSkills = fetchedSkills.map(skill => ({
                    name: skill,
                    existsInParsedCv: parsedCvData.skills
                        ? parsedCvData.skills.some(skillCategory =>
                            skillCategory.skills.some(parsedSkill => parsedSkill.toLowerCase() === skill)
                        )
                        : false,
                }));

                setFormData(prevData => ({
                    ...prevData,
                    skills: formattedSkills.filter(skill => skill.existsInParsedCv),
                }));
            } catch (error) {
                console.log(error);
            }
        };

        fetchSkills();
    }, [parsedCvData]);
    const handleRemoveEducation = (index) => {
        const updatedEducation = [...formData.education];
        updatedEducation.splice(index, 1);
        setFormData({
            ...formData,
            education: updatedEducation,
        });
    };
    const handleRemoveExperience = (index) => {
        const updateExperience = [...formData.work_experience];
        updateExperience.splice(index, 1);
        setFormData({
            ...formData,
            work_experience: updateExperience,
        });
    };
    const handleWorkingChange = (index, checked) => {
        setFormData(prevData => ({
            ...prevData,
            work_experience: prevData.work_experience.map((work, i) =>
                i === index
                    ? { ...work, currently_working: checked, end_date: checked ? '' : work.end_date } // Clear end_date if currently working is checked
                    : work
            )
        }));
    };
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const res = await axios.get(`${config.apiUrl}candidate/profile`, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    }
                });
                console.log(res.data)
                if (res.status === 200) {
                    const profileData = res.data;
                    console.log(profileData)
                    setFormData({
                        general_details: {
                            full_name: profileData.general_details.full_name || '',
                            phone: profileData.general_details.phone || '',
                            email: profileData.general_details.email || '',
                            location: profileData.general_details.location || '',
                            date_of_birth: profileData.general_details.date_of_birth || '',
                            gender: profileData.general_details.gender || '',
                            country_code: profileData.general_details.country_code || '+91',
                        },
                        education: profileData.education && profileData.education.length > 0 ?
                            profileData.education.map(edu => ({
                                college: edu.college || '',
                                degree: edu.degree || '',
                                field_of_study: edu.field_of_study || '',
                                start_date: edu.start_date || '',
                                end_date: edu.end_date || '',
                                grade: edu.grade || '',
                            })) : [{ college: '', degree: '', field_of_study: '', start_date: '', end_date: '', grade: '' }],
    
                        work_experience: profileData.work_experience && profileData.work_experience.length > 0 ?
                            profileData.work_experience.map(work => ({
                                total_work_experience_months: work.total_work_experience_months==0?0:work.total_work_experience_months || '',
                                designation: work.designation || '',
                                company: work.company || '',
                                location: work.location || '',
                                start_date: work.start_date || '',
                                end_date: work.end_date || '',
                                description: work.description || '',
                                currently_working: work.currently_working || false,
                            })) : [{
                                total_work_experience_months: '',
                                designation: '',
                                company: '',
                                location: '',
                                start_date: '',
                                end_date: '',
                                description: '',
                                currently_working: false,
                            }],
    
                        skills: profileData.skills && profileData.skills.length > 0 ?
                            profileData.skills.map(skill => ({
                                name: skill.name || '',
                            })) : [{ name: '' }],
    
                        portfolio: {
                            portfolio_link: profileData.portfolio.portfolio_link || '',
                            github_link: profileData.portfolio.github_link || '',
                            other_link: profileData.portfolio.other_link || ''
                        }
                    });
                    setIsUpdating(true)
                }
            } catch (error) {
                console.error('Error fetching profile data:', error);
            } 
        };
    
        fetchProfileData();
    }, []);
    
    return (
        <>
            <Toaster />
            <Loader openLoader={openLoader} />
            <div>
                <h3 className="sub-heading">General Details</h3>

                <form className="mt-10 form-theme">
                    <div className="grid gap-6 mb-6 md:grid-cols-3">
                        <div>
                            <label for="first_name" className="block mb-2">Full Name</label>
                            <input type="text" id="first_name" className="w-full border border-gray-300" placeholder="Full name"
                                name="general_details.full_name"
                                value={formData.general_details.full_name}
                                onChange={handleChange}
                            />
                            {errors["general_details.full_name"] && <span className="text-red-500 text-sm">{errors["general_details.full_name"]
                            }</span>}
                        </div>
                        <div>
                            <label for="last_name" className="block mb-2">Date of Birth</label>
                            <input type="text" id="last_name" className="w-full border border-gray-300" placeholder="YYYY-MM-DD"
                                name="general_details.date_of_birth"
                                value={formData.general_details.date_of_birth}
                                onChange={handleChange}
                            />
                            {errors["general_details.date_of_birth"] && <span className="text-red-500 text-sm">{errors["general_details.date_of_birth"]}</span>}
                        </div>
                        <div>
                            <label for="company" className="block mb-2">Gender</label>
                            <select className="w-full border border-gray-300"
                                value={formData.general_details.gender}
                                onChange={handleChange}
                                name="general_details.gender"
                            >
                                <option value="">Gender</option>
                                <option value="male">male</option>
                                <option value="female">female</option>
                                <option value="non-binary">non-binary</option>
                                <option value="prefer not to say">prefer not to say</option>

                            </select>
                            {errors["general_details.gender"] && <span className="text-red-500 text-sm">{errors["general_details.gender"]}</span>}

                        </div>
                        <div>
                            <label for="phone" className="block mb-2">Phone number</label>
                            <div className="relative flex">
                                <select
                                    name="general_details.country_code"
                                    className="border border-gray-300 w-20 bg-gray-200"
                                    value={formData.general_details.country_code}
                                    onChange={handleChange}
                                    style={{ borderRadius: "5px 0 0 5px" }}
                                >
                                    <option value="+91" className="text-center text-gray-100"><span className="text-gray-100">+91</span></option>
                                    {countryCode.map((items) => (
                                        <option key={items} className="text-center" value={items}>{items}</option>
                                    ))}
                                </select>
                                <input type="tel" id="phone" className="w-full border border-gray-300" placeholder="123-45-678" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                    name="general_details.phone"
                                    value={formData.general_details.phone}
                                    onChange={handleChange}
                                    style={{ borderRadius: "0 5px 5px 0" }}
                                />
                            </div>
                            {errors["general_details.country_code"] && <span className="text-red-500 text-sm">{errors["general_details.country_code"]}</span>}
                            <br />
                            {errors["general_details.phone"] && <span className="text-red-500 text-sm">{errors["general_details.phone"]}</span>}
                        </div>
                        <div>
                            <label for="website" className="block mb-2">Email Id</label>
                            <input type="url" id="website" className="w-full border border-gray-300" placeholder="exaple@gmail.com"
                                name="general_details.email"
                                value={formData.general_details.email}
                                onChange={handleChange} />
                            {errors["general_details.email"] && <span className="text-red-500 text-sm">{errors["general_details.email"]}</span>}
                        </div>
                        <div>
                            <label for="visitors" className="block mb-2">Location</label>
                            <select
                                name="general_details.location"
                                className="w-full border border-gray-300"
                                value={formData.general_details.location}
                                onChange={handleChange}
                            >
                                <option value="">Location</option>
                                {location.map((items) => (
                                    <option key={items} value={items}>{items}</option>
                                ))}
                            </select>
                            {errors["general_details.location"] && <span className="text-red-500 ">{errors["general_details.location"]}</span>}
                        </div>
                    </div>

                </form>
            </div>
            <div className="pt-5">
                <h3 className="sub-heading">Professional Details</h3>
                {/* ////Education////////////////////////// */}
                <form className="mt-5 form-theme" onSubmit={handleSubmit}>
                    <label htmlFor="education" className="block mb-5">Education</label>
                    {formData.education.map((edu, index) => (
                        <React.Fragment key={index}>
                            {formData.education.length > 1 && (
                                <CloseIcon onClick={() => handleRemoveEducation(index)} className="absolute right-0 cursor-pointer" />
                            )}
                            <div className="grid gap-6 mb-6 md:grid-cols-3">
                                <div>
                                    <label htmlFor={`college_${index}`} className="block mb-2">Institute Name</label>
                                    <input
                                        type="text"
                                        id={`college_${index}`}
                                        className="w-full border border-gray-300"
                                        placeholder="e.g. Hindu College"
                                        name={`education.college.${index}`}
                                        value={edu.college}
                                        onChange={handleChange}
                                    />

                                    {(errors && errors[`education[${index}].college`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].college`]}</span>
                                    ) : null}

                                </div>
                                <div>
                                    <label htmlFor={`degree_${index}`} className="block mb-2">Degree</label>
                                    <input
                                        type="text"
                                        id={`degree_${index}`}
                                        className="w-full border border-gray-300"
                                        placeholder="e.g. Bachelor’s of Architecture"
                                        name={`education.degree.${index}`}
                                        value={edu.degree}
                                        onChange={handleChange}
                                    />
                                    {(errors && errors[`education[${index}].degree`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].degree`]}</span>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor={`field_of_study_${index}`} className="block mb-2">Field of Study</label>
                                    <select
                                        id={`field_of_study_${index}`}
                                        name={`education.field_of_study.${index}`}
                                        className="w-full border border-gray-300"
                                        value={edu.field_of_study}
                                        onChange={handleChange}
                                    >
                                        <option value="">e.g. Architecture</option>
                                        {fieldStudy.map((items) => (
                                            <option key={items} value={items}>{items}</option>
                                        ))}
                                    </select>
                                    {(errors && errors[`education[${index}].field_of_study`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].field_of_study`]}</span>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor={`start_date_${index}`} className="block mb-2">Start Date</label>
                                    <input
                                        type="text"
                                        id={`start_date_${index}`}
                                        className="w-full border border-gray-300"
                                        placeholder="YYYY-MM-DD"
                                        name={`education.start_date.${index}`}
                                        value={edu.start_date}
                                        onChange={handleChange}
                                    />
                                    {(errors && errors[`education[${index}].start_date`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].start_date`]}</span>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor={`end_date_${index}`} className="block mb-2">End Date</label>
                                    <input
                                        type="text"
                                        id={`end_date_${index}`}
                                        className="w-full border border-gray-300"
                                        placeholder="YYYY-MM-DD"
                                        name={`education.end_date.${index}`}
                                        value={edu.end_date}
                                        onChange={handleChange}
                                    />
                                    {(errors && errors[`education[${index}].end_date`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].end_date`]}</span>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor={`grade_${index}`} className="block mb-2">Grade</label>
                                    <input
                                        type="text"
                                        id={`grade_${index}`}
                                        className="w-full border border-gray-300"
                                        placeholder="Grade / CGPA"
                                        name={`education.grade.${index}`}
                                        value={edu.grade}
                                        onChange={handleChange}
                                    />
                                    {(errors && errors[`education[${index}].grade`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].grade`]}</span>
                                    ) : null}
                                </div>
                            </div>
                            <hr className="mb-4" />
                        </React.Fragment>
                    ))}
                    <button
                        type="button"
                        className="theme-btn bordered-btn py-2.5"
                        onClick={handleAddEducation}
                    >
                        <span className="color-text">+ Add Education</span>
                    </button>
                </form>
                {/* ////work experience/////////////////// */}
                <form className="mt-10 form-theme">
                    <label className="block mb-2">Work Experience</label>
                    <div className="mb-3" style={{ width: "200px" }}>
                        <label className="block mb-2">Total work experience</label>
                        {console.log(formData.work_experience[0],"?")}
                        <input
                            type="text"
                            className="w-full border border-gray-300"
                            placeholder="In months"
                            name="work_experience.total_work_experience_months.0"
                            value={formData.work_experience[0]?.total_work_experience_months}
                            onChange={handleChange}
                        />
                        {(errors && errors[`work_experience[0].total_work_experience_months`]) ? (
                            <span className="text-red-500 text-sm">{errors[`work_experience[0].total_work_experience_months`]}</span>
                        ) : null}
                    </div>
                    {parseInt(formData.work_experience[0]?.total_work_experience_months, 10) > 0 && (
                        <React.Fragment>
                            {formData.work_experience.map((work, index) => (
                                <div key={index}>
                                    {formData.work_experience.length > 1 && (
                                        <CloseIcon onClick={() => handleRemoveExperience(index)} className="absolute right-0 cursor-pointer" />
                                    )}
                                    <div className="grid gap-6 mb-6 md:grid-cols-3">
                                        <div>
                                            <label htmlFor={`designation_${index}`} className="block mb-2">Designation</label>
                                            <input
                                                type="text"
                                                className="w-full border border-gray-300"
                                                placeholder="e.g. UI/UX Designer"
                                                name={`work_experience.designation.${index}`}
                                                value={work.designation}
                                                onChange={handleChange}
                                            />
                                            {/* {( parseInt(formData.work_experience[0]?.total_work_experience_months, 10) > 0 && errors && errors[`work_experience[${index}].designation`]) ? (
                                                <span className="text-red-500 text-sm">{errors[`work_experience[${index}].designation`]}</span>
                                            ) : null} */}

                                        </div>
                                        <div>
                                            <label htmlFor={`company_${index}`} className="block mb-2">Company</label>
                                            <input
                                                type="text"
                                                className="w-full border border-gray-300"
                                                placeholder="e.g. Google"
                                                name={`work_experience.company.${index}`}
                                                value={work.company}
                                                onChange={handleChange}
                                            />
                                            {/* {(parseInt(formData.work_experience[0]?.total_work_experience_months, 10) > 0 && errors && errors[`work_experience[${index}].company`]) ? (
                                                <span className="text-red-500 text-sm">{errors[`work_experience[${index}].company`]}</span>
                                            ) : null} */}
                                        </div>
                                        <div>
                                            <label htmlFor={`location_${index}`} className="block mb-2">Location</label>
                                            <select
                                                name={`work_experience.location.${index}`}
                                                className="w-full border border-gray-300"
                                                value={work.location}
                                                onChange={handleChange}
                                            >
                                                <option value="">Location</option>
                                                {location.map((items) => (
                                                    <option key={items} value={items}>{items}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor={`start_date_${index}`} className="block mb-2">Start Date</label>
                                            <input
                                                type="text"
                                                id={`start_date_${index}`}
                                                className="w-full border border-gray-300"
                                                placeholder="YYYY-MM-DD"
                                                name={`work_experience.start_date.${index}`}
                                                value={work.start_date}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {!work.currently_working && (
                                            <div>
                                                <label htmlFor={`end_date_${index}`} className="block mb-2">End Date</label>
                                                <input
                                                    type="text"
                                                    id={`end_date_${index}`}
                                                    className="w-full border border-gray-300"
                                                    placeholder="YYYY-MM-DD"
                                                    name={`work_experience.end_date.${index}`}
                                                    value={work.end_date}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <label htmlFor={`description_${index}`} className="block mb-2">Description</label>
                                            <input
                                                type="text"
                                                id={`description_${index}`}
                                                className="w-full border border-gray-300"
                                                placeholder="Description"
                                                name={`work_experience.description.${index}`}
                                                value={work.description}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center mb-5">
                                        <input
                                            type="checkbox"
                                            id={`currently_working_${index}`}
                                            name={`work_experience.currently_working.${index}`}
                                            checked={work.currently_working}
                                            onChange={(e) => handleWorkingChange(index, e.target.checked)}
                                        />
                                        <label htmlFor={`currently_working_${index}`} className="ml-2">Currently Working</label>
                                    </div>
                                </div>
                            ))}
                            <button
                                type="button"
                                className="theme-btn bordered-btn py-2.5"
                                onClick={handleAddWorkExperience}
                            >
                                <span className="color-text">+ Add Work Experience</span>
                            </button>
                        </React.Fragment>
                    )}

                </form>

                {/* /////////skills/////////// */}
                <form className="mt-5 form-theme">
                    <label htmlFor="skills" className="block mb-2">Skills</label>
                    <div>

                        <Select
                            multiple={true}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            className="w-96 border border-gray mb-2"
                            name="skills"
                            value={formData.skills.map(skill => skill.name)}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (

                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>

                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {getskills.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    {errors.skills && (
                        <span className="text-red-500 text-sm">{errors.skills}</span>
                    )}
                </form>
                {/* ///////////////Links/////////////// */}
                <form className="mt-10 form-theme">
                    <label for="first_name" className="block mb-2">Work samples</label>
                    <div className="grid gap-6 mb-6 md:grid-cols-3">
                        <div>
                            <label for="first_name" className="block mb-2">GitHub link</label>
                            <input type="text" id="first_name" className="w-full border border-gray-300" placeholder="GitHub link"
                                name="portfolio.portfolio_link"
                                value={formData.portfolio.portfolio_link}
                                onChange={handleChange} />
                        </div>
                        <div>
                            <label for="first_name" className="block mb-2">Portfolio link</label>
                            <input type="text" id="first_name" className="w-full border border-gray-300" placeholder="Portfolio Link"
                                name="portfolio.github_link"
                                value={formData.portfolio.github_link}
                                onChange={handleChange} />
                        </div>
                        <div>
                            <label for="first_name" className="block mb-2">Other Work Link</label>
                            <input type="text" id="first_name" className="w-full border border-gray-300" placeholder="Other Links"
                                name="portfolio.other_link"
                                value={formData.portfolio.other_link}
                                onChange={handleChange} />
                        </div>
                    </div>
                </form>
                {/* <button type="button" className="theme-btn bordered-btn py-2 mr-2"><span className="color-text ">Save</span></button> */}
                <button type="button" className="theme-btn bordered-btn py-2" onClick={(e) => (handleSubmit(e))}>
                    <span className="color-text ">       {isUpdating ? 'Update' : 'Submit'}</span>
                    </button>

            </div>
        </>
    )
}
export default MyProfile;