import React, { useState } from "react";
import config from "../../../config/config";
import * as Yup from 'yup';
import axios from "axios";
import { Toaster, resolveValue, toast } from 'react-hot-toast';
import { Link } from "react-router-dom";
import LoginFooter from "../loginFooter";
import { Auth } from "../../../redux copy/actionTypes";
import { useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';



const WithOTP = ({ switchToPassword }) => {
  const [email, setEmail] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // Add loading state


  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required'),
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate({ email });

      const response = await axios.post(`${config.apiUrl}candidate/login/otp`, { email: email });

      if (response.status === 200) {
        toast.success("OTP has been sent")
        setShowOtpInput(true)
      } else {
        toast.error("Something went wrong");
      }


    } catch (error) {
      if (error.response) {
        // Server-side error
        console.error("API Error:", error.response.data);
        toast.error(error.response.data.detail);
      } else if (error.request) {
        // Network error
        console.error("Network Error:", error.request);
        toast.error("Network error occurred");
      } else {
        // Validation error or other client-side error
        console.error("Validation Error:", error.message);
        toast.error(error.message);
      }

    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(`${config.apiUrl}candidate/login/otp/verify`, { otp, email: email });

      if (response.status === 200) {
        toast.success("Login Successfully");
        dispatch({
          type: Auth,
          payload: response.data
        })
        if (response.data.cv_exists === true) {
          window.location.href = `${config.candidateUrl}setting`;
        }
        else {
          window.location.href = `${config.baseUrl}candidate/dashboard`;
        }

      } else {
        toast.error("Failed to verify OTP");
      }

    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.data);
        toast.error(error.response.data.detail || "API error occurred");
      }
      else if (error.request) {
        console.error("Network Error:", error.request);
        toast.error("Network error occurred");
      }
      else {
        console.error("Validation Error:", error.message);
        toast.error(error.message);
      }
    }
    finally{
      setLoading(false)
    }
  };



  return (
    <>
      <Toaster />
      <div className="">
        {!showOtpInput ? (
          <form className="space-y-4" onSubmit={handleLogin}>
            <div>
              <label className="block">Email</label>
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                placeholder="Enter your name"
              />
            </div>
            <div className="flex items-center justify-between mb-4">
              <div>
                <input id="default-checkbox" type="checkbox" />
                <label htmlFor="default-checkbox" className="ms-2 dark:text-gray-300">Remember me</label>
              </div>
              <div>
                <Link to="#" onClick={switchToPassword}>Login with Password</Link>
              </div>
            </div>
            <div>
              <button type="submit" className="theme-btn mr-2 text-white w-full">Send OTP</button>
            </div>
          </form>
        )
          :
          (
            <form className="space-y-4 mt-4 px-5" onSubmit={handleOtpSubmit}>
              <div>
                <label className="block">Enter OTP</label>
                <input
                  type="text"
                  name="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                  placeholder="Enter the OTP"
                />
              </div>
              <div>
                <button type="submit" className="theme-btn mr-2 text-white">Continue
              {loading == true ?
                <CircularProgress
                  size={25}
                  disableShrink
                  style={{ color: "#fff", margin: "0 10px" }} />
                : null}
                </button>
              </div>
            </form>
          )}
      </div>
    </>
  );
};

export default WithOTP;
