import React, { Suspense } from "react";
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { MoreHorizRounded } from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Scheduled = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
  <button type="button" className="color-btn"><img src="images/icons/plus.svg" className="inline"/>  Schedule Interview</button>

      <Box className="">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="hirree-tabs bg-white">
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="bg-white" >
            <Tab label="In-progress" {...a11yProps(0)} />
            <Tab label="Completed" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className="flex justify-between">
            <p>Tomorrow - 10 July, 2021</p>
            <MoreHorizRounded />
          </div>
          <div className="interview flex justify-between items-center mt-3 ">
            <div className="flex items-center">
              <img src="images/man.svg" className="mr-3" />
              <div>
                <h3>Katha Menon</h3>
                <h5>Technical Round 1</h5>
              </div>
            </div>
            <div>
            <h3>10:00 AM - 11:30 AM</h3>
            <h5>Location : Noida sector 10</h5>
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>

        </CustomTabPanel>
      </Box>
    </>
  )
}
export default Scheduled;