import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from 'react-redux';
import config from "../../../../config/config";
import { getLocation } from "../../../../utils/candidate.api/candidate.api.services";
import * as Yup from 'yup';
import { toast, Toaster } from "react-hot-toast";


const Prefrence = () => {
    const loginData = useSelector((state) => state.auth.Auth);
    const [location, setLocation] = useState([])
    const [formData, setFormData] = useState({
        job_type: "",
        job_mode: "",
        current_location: "",
        preferred_location: "",
        notice_period: "",
        work_experience: "",
        current_salary: "",
        expected_salary_min: "",
        expected_salary_max: "",
        job_category: "",
        remaining_working_days: "",
    });

    const validationSchema = Yup.object().shape({
        job_type: Yup.string().required('Job type is required'),
        job_mode: Yup.string().required('Job mode is required'),
        current_location: Yup.string().required('Current location is required'),
        preferred_location: Yup.string().required('Preferred location is required'),
        notice_period: Yup.string().required('Notice period is required'),
        work_experience: Yup.string().required('Work experience is required'),
        job_category: Yup.string().required('Job category is required'),
        current_salary: Yup.number()
          .typeError('Current salary must be a number')
          .positive('Current salary must be positive')
          .required('Current salary is required'),
        expected_salary_min: Yup.number()
          .typeError('Minimum expected salary must be a number')
          .positive('Minimum expected salary must be positive')
          .required('Minimum expected salary is required'),
        expected_salary_max: Yup.number()
          .typeError('Maximum expected salary must be a number')
          .positive('Maximum expected salary must be positive')
          .required('Maximum expected salary is required'),
      });

    const [errors, setErrors] = useState({});
    const [isUpdating, setIsUpdating] = useState(false);
    const handleChange = async (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });
        if (name === 'notice_period' && value !== 'Serving notice period' && value !== 'Immediate/15 days') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                remaining_working_days: '',
            }));
        }
        try {
            await validationSchema.validateAt(name, { [name]: value });
            setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
        } catch (error) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
        }
    };

    

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                let response = await getLocation()
                setLocation(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchLocation()
    }, [])

    const noticePeriod = [
        { name: "Immediate/15 days" },
        { name: "30 days" },
        { name: "60 days" },
        { name: "90 days or more" },
        { name: "Serving notice period" }
    ]
    useEffect(() => {
        const fetchPreferenceData = async () => {
            try {
                const res = await axios.get(`${config.apiUrl}candidate/preferences`, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    }
                });
                if (res.data && res.data.length > 0) {
                 const preferData = res.data[0];
                    console.log(res.data)
                    setFormData({
                        job_type: preferData.job_type,
                        job_mode: preferData.job_mode,
                        current_location: preferData.current_location,
                        preferred_location: preferData.preferred_location,
                        notice_period: preferData.notice_period,
                        work_experience: preferData.work_experience,
                        current_salary: preferData.current_salary,
                        expected_salary_min: preferData.expected_salary_min,
                        expected_salary_max: preferData.expected_salary_max,
                        job_category: preferData.job_category,
                        remaining_working_days: preferData.remaining_working_days,
                    });
                    setIsUpdating(true);  
                }
            } catch (error) {
                console.error('Error fetching profile data:', error);
            } 
        };
    
        fetchPreferenceData();
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            if (isUpdating) {
             const response = await axios.patch(`${config.apiUrl}candidate/preferences`, formData, {
                  headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                  }
                });
                if (response.status == 200) {
                    toast.success(response.data.message)
                }
              } else {
                const response =  await axios.post(`${config.apiUrl}candidate/preferences`, formData, {
                  headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                  }
                });
                if (response.status == 201) {
                    toast.success(response.data.message)
                }
              }
        
         
        } catch (error) {
            console.log("this is",error)
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(err => {
                    validationErrors[err.path] = err.message;
                });
                setErrors(validationErrors);
            } else {
                console.error("Unexpected error:", error);
            }
        }
    };
    return (
        <>
            <Toaster />
            <div>
                <form className="mt-10 form-theme">
                    <div className="grid gap-6 mb-6 md:grid-cols-3">
                        <div>
                            <label htmlFor="job_type" className="block mb-2">Job Type</label>
                            <select
                                id="job_type"
                                name="job_type"
                                className="w-full border border-gray-300"
                                value={formData.job_type}
                                onChange={handleChange}
                            >
                                <option value="">Job Type</option>
                                <option value="Full-time">Full-time</option>
                                <option value="Internship">Internship</option>
                                <option value="Contractual">Contractual</option>
                            </select>
                            {errors.job_type && <span className="text-red-500 text-sm">{errors.job_type}</span>}
                        </div>
                        <div>
                            <label htmlFor="job_mode" className="block mb-2">Job Mode</label>
                            <select
                                id="job_mode"
                                name="job_mode"
                                className="w-full border border-gray-300"
                                value={formData.job_mode}
                                onChange={handleChange}
                            >
                                <option value="">Job Mode</option>
                                <option value="Hybrid">Hybrid</option>
                                <option value="Remote">Remote</option>
                                <option value="On-site">On-site</option>
                            </select>
                            {errors.job_mode && <span className="text-red-500 text-sm">{errors.job_mode}</span>}
                        </div>
                        <div>
                            <label htmlFor="current_location" className="block mb-2">Current Location</label>
                            <select
                                id="current_location"
                                name="current_location"
                                className="w-full border border-gray-300"
                                value={formData.current_location}
                                onChange={handleChange}
                            >
                                <option value="">Current Location</option>
                                {location.map((items) => (
                                    <option key={items} value={items}>{items}</option>
                                ))}
                            </select>
                            {errors.current_location && <span className="text-red-500 text-sm">{errors.current_location}</span>}
                        </div>
                        <div>
                            <label htmlFor="preferred_location" className="block mb-2">Preferred Location</label>
                            <select
                                id="preferred_location"
                                name="preferred_location"
                                className="w-full border border-gray-300"
                                value={formData.preferred_location}
                                onChange={handleChange}
                            >
                                <option value="">Preferred Location</option>
                                {location.map((items) => (
                                    <option key={items} value={items}>{items}</option>
                                ))}
                            </select>
                            {errors.preferred_location && <span className="text-red-500 text-sm">{errors.preferred_location}</span>}
                        </div>
                        <div>
                            <label htmlFor="notice_period" className="block mb-2">Notice Period</label>
                            <select
                                id="notice_period"
                                name="notice_period"
                                className="w-full border border-gray-300"
                                value={formData.notice_period}
                                onChange={handleChange}
                            >
                                <option value="">Notice Period</option>
                                {noticePeriod.map((items) => (
                                    <option key={items.name} value={items.name}>{items.name}</option>
                                ))}
                            </select>
                            {errors.notice_period && <span className="text-red-500 text-sm">{errors.notice_period}</span>}
                        </div>
                        {formData.notice_period === "Serving notice period" || formData.notice_period === "Immediate/15 days"
                            ?
                            <div>
                                <label htmlFor="job_category" className="block mb-2">Remaing Working Days</label>
                                <input
                                    type="number"
                                    name="remaining_working_days"
                                    className="w-full border border-gray-300"
                                    placeholder="Number of Days"
                                    value={formData.remaining_working_days}
                                    onChange={handleChange}
                                />
                            </div> : ""}

                        <div>
                            <label htmlFor="work_experience" className="block mb-2">Total Work Experience in months</label>
                            <input
                                type="number"
                                name="work_experience"
                                className="w-full border border-gray-300"
                                placeholder="In Months only"
                                value={formData.work_experience}
                                onChange={handleChange}
                            />

                            {errors.work_experience && <span className="text-red-500 text-sm">{errors.work_experience}</span>}
                        </div>
                        <div>
                            <label htmlFor="current_salary" className="block mb-2">Current Package</label>
                            <input
                                type="number"
                                name="current_salary"
                                className="w-full border border-gray-300"
                                placeholder="CTC"
                                value={formData.current_salary}
                                onChange={handleChange}
                            />
                            {errors.current_salary && <span className="text-red-500 text-sm">{errors.current_salary}</span>}
                        </div>
                        <div>
                            <label htmlFor="expected_salary_min" className="block mb-2">Expected Package</label>
                            <div className="grid gap-6 mb-6 md:grid-cols-2">
                                <div>
                                    <input
                                        type="number"
                                        name="expected_salary_min"
                                        className="w-full border border-gray-300"
                                        placeholder="Min."
                                        value={formData.expected_salary_min}
                                        onChange={handleChange}
                                    />
                                    {errors.expected_salary_min && <span className="text-red-500 text-sm">{errors.expected_salary_min}</span>}
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        name="expected_salary_max"
                                        className="w-full border border-gray-300"
                                        placeholder="Max."
                                        value={formData.expected_salary_max}
                                        onChange={handleChange}
                                    />
                                    {errors.expected_salary_max && <span className="text-red-500 text-sm">{errors.expected_salary_max}</span>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="job_category" className="block mb-2">Job Category</label>
                            <input
                                type="text"
                                name="job_category"
                                className="w-full border border-gray-300"
                                placeholder="Job Category"
                                value={formData.job_category}
                                onChange={handleChange}
                            />
                            {errors.job_category && <span className="text-red-500 text-sm">{errors.job_category}</span>}
                        </div>
                    </div>
                    <button type="button" className="theme-btn bordered-btn py-2.5" onClick={(e)=>handleSubmit(e)}>
                        <span className="color-text">   {isUpdating ? 'Update' : 'Submit'}</span>
                    </button>
                </form>
            </div>
        </>
    );
};

export default Prefrence;
