import React, { useEffect, useState } from "react";
import { getIndustry } from "../../../utils/employer.api/employer.api.services";
import { getLocation } from "../../../utils/candidate.api/candidate.api.services";
import config from "../../../config/config";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import * as Yup from 'yup';

const CompanyInfo = () => {
    const [industry, setIndustry] = useState([]);
    const [location, setLocation] = useState([]);
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [banner, setBanner] = useState(null);
    const [bannerPreview, setBannerPreview] = useState(null);
    const [ isProfileExists, setIsProfileExists] = useState(false)
    const [errors, setErrors] = useState({});

    const loginData = useSelector((state) => state.auth.Auth);

    const [formData, setFormData] = useState({
        company_name: "",
        website: "",
        location: "",
        team_size: "",
        industry: "",
        year_of_establishment: "",
        about_us: ""
    });
    useEffect(() => {
        fetchIndustry();
        fetchLocation();
        fetchLogo();
        fetchBanner();
    }, []);

    const validationSchema = Yup.object().shape({
        company_name : Yup.string().required('Company name required'),
        website: Yup.string().required('URL required'),
        location: Yup.string().required('Location required'),
        team_size: Yup.string().required('Team size required'),
        industry: Yup.string().required('Industry name required'),
        year_of_establishment: Yup.string().required('Please fill year'),
        about_us: Yup.string().required('Please write '),
      });
    const fetchIndustry = async () => {
        try {
            const response = await getIndustry();
            setIndustry(response.data);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchLocation = async () => {
        try {
            const response = await getLocation();
            setLocation(response.data);
        } catch (error) {
            console.log(error);
        }
    };


    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        try {
            await validationSchema.validateAt(name, { [name]: value });
            setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
        } catch (error) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
        }
    };
    const handleLogoUpload = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];

        if (!file) return;

        // Check the image dimensions before uploading
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = async function () {
            const width = img.width;
            const height = img.height;

            // Check if dimensions exceed the limit
            if (width > 400 || height > 400) {
                toast.error("Logo image dimensions should not exceed 400x400.");
                return; // Prevent upload if dimensions are too large
            }

            let image_as_base64 = URL.createObjectURL(file); // For preview
            setLogo(file); // Set file
            setLogoPreview(image_as_base64); // Preview image

            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post(`${config.apiUrl}employer/company/logo`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    }
                });

                if (response.status === 200) {
                    toast.success("Logo uploaded successfully!");
                }
            } catch (error) {
                toast.error("Error uploading logo. Please try again.");
                console.log("Error uploading logo:", error);
            }
        };
    };

    const handleBannerUpload = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];

        if (!file) return;
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = async function () {
            const width = img.width;
            const height = img.height;

            // Check if dimensions exceed the limit
            if (width > 1520 || height > 400) {
                toast.error("Banner image dimensions should not exceed 1520x400.");
                return; // Prevent upload if dimensions are too large
            }

            let image_as_base64 = URL.createObjectURL(file); // For preview

            setBanner(file); // Set file
            setBannerPreview(image_as_base64); // Preview image

            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post(`${config.apiUrl}employer/company/banner`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    },
                });

                if (response.status === 200) {
                    toast.success("Banner uploaded successfully!");
                }
            } catch (error) {
                toast.error("Error uploading banner. Please try again.");
                console.log("Error uploading banner:", error);
            }
        };
    };

    const handleSubmit = async () => {
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            const method = isProfileExists ? 'put' : 'post'; // Use PUT if profile exists, POST otherwise

            const response = await axios({
                method: method,
                url: `${config.apiUrl}employer/company`,
                data: formData,
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                }
            });

            if (response.status === 200 || response.status === 201) {
                const successMessage = isProfileExists
                    ? "Company profile updated successfully!"
                    : "Company profile created successfully!";
                toast.success(successMessage);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(err => {
                    validationErrors[err.path] = err.message;
                });
                setErrors(validationErrors);
            } else {
                console.error("Unexpected error:", error);
            }
        }
    };

    const fetchLogo = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}employer/company/logo`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                    'Content-Type': 'arraybuffer'
                },
                responseType: 'arraybuffer'
            });

            if (response.status === 200) {
                if (response.data.byteLength <= 2) {
                    setLogoPreview("")
                    return
                }
                const blob = new Blob([response.data], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                setLogoPreview(imageUrl);
            }
        } catch (error) {
            console.error("Error fetching profile picture:", error);
        }
    };
    const fetchBanner = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}employer/company/banner`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                    'Content-Type': 'arraybuffer'
                },
                responseType: 'arraybuffer'
            });

            if (response.status === 200) {
                if (response.data.byteLength <= 2) {
                    setBannerPreview("")
                    return
                }
                const blob = new Blob([response.data], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                setBannerPreview(imageUrl);
            }
        } catch (error) {
            console.error("Error fetching profile picture:", error);
        }
    };
    useEffect(() => {
    const fetchProfileData = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}employer/company-info`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                },
            });
            if (response.data) {
                const info = response.data
                setFormData({
                    company_name: info.company_name,
                    website: info.website,
                    location: info.location,
                    team_size: info.team_size,
                    industry: info.industry,
                    year_of_establishment: info.year_of_establishment,
                    about_us: info.about_us
                })
                setIsProfileExists(true);
            }
            else{
                setIsProfileExists(false);
            }
        } catch (error) {
            console.log(error)
        }
    }
    fetchProfileData();
}, [loginData.access_token]);
    return (
        <>
            <Toaster />
            <div>
                <div>
                    <h2 className="heading3">Logo & Banner Image</h2>
                    <div className="grid grid-cols-12 gap-4 mt-4">
                        <div className="col-span-4">
                            <label>Upload Image</label>
                            <div className="flex items-center mt-3">
                                <label className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer">
                                    <div className="flex flex-col items-center justify-center  text-center company-img">
                                        {logoPreview ? (
                                            <img src={logoPreview} alt="Logo Preview" className="h-full w-full object-cover" />
                                        ) : (
                                            <>
                                                <img src="images/icons/upload.svg" className="mb-2" alt="Upload Icon" />
                                                <p className="mb-2 text-sm text-black">Browse image or drag and drop here</p>
                                                <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (max. 400 x 400px)</p>
                                            </>
                                        )}
                                    </div>
                                    <input id="dropzone-file" className="hidden" type="file" onChange={handleLogoUpload} />
                                </label>
                            </div>
                        </div>
                        <div className="col-span-8">
                            <label>Upload Banner</label>
                            <div className="flex items-center mt-3">
                                <label className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer">
                                    <div className="flex flex-col items-center justify-center  text-center company-img">
                                        {bannerPreview ? (
                                            <img src={bannerPreview} alt="Logo Preview" className="h-full w-full object-cover" />
                                        ) : (
                                            <>
                                                <img src="images/icons/upload.svg" className="mb-2" alt="Upload Icon" />
                                                <p className="mb-2 text-sm text-black">Browse image or drag and drop here</p>
                                                <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (max. 400 x 400px)</p>
                                            </>
                                        )}
                                    </div>
                                    <input id="dropzone-file" className="hidden" type="file" onChange={handleBannerUpload} />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <form className="mt-10 form-theme">
                    <div className="grid gap-6 mb-6 md:grid-cols-3">
                        <div>
                            <label className="block mb-2">Company name</label>
                            <input
                                type="text"
                                name="company_name"
                                className="w-full border border-gray-300"
                                placeholder="Name"
                                value={formData.company_name}
                                onChange={handleChange}
                            />
                             {errors.company_name && <span className="text-red-500 text-sm">{errors.company_name}</span>}
                        </div>
                        <div>
                            <label className="block mb-2">Website</label>
                            <input
                                type="text"
                                name="website"
                                className="w-full border border-gray-300"
                                placeholder="URL"
                                value={formData.website}
                                onChange={handleChange}
                            />
                             {errors.website && <span className="text-red-500 text-sm">{errors.website}</span>}

                        </div>
                        <div>
                            <label htmlFor="location" className="block mb-2">Location</label>
                            <select
                                id="location"
                                name="location"
                                className="w-full border border-gray-300"
                                value={formData.location}
                                onChange={handleChange}
                            >
                                <option value="">Location</option>
                                {location.map(item => (
                                    <option>{item}</option>
                                ))}
                            </select>
                            {errors.location && <span className="text-red-500 text-sm">{errors.location}</span>}

                        </div>
                        <div>
                            <label htmlFor="team_size" className="block mb-2">Team size</label>
                            <select
                                id="team_size"
                                name="team_size"
                                className="w-full border border-gray-300"
                                value={formData.team_size}
                                onChange={handleChange}
                            >
                                <option value="">Team size</option>
                                <option value="0-10">0-10</option>
                                <option value="20-50">20-50</option>
                                <option value="1000-5000">1000-5000</option>
                                <option value="10000-20000">10000-20000</option>
                                <option value="50000+">50000+</option>
                            </select>
                            {errors.team_size && <span className="text-red-500 text-sm">{errors.team_size}</span>}
                        </div>
                        <div>
                            <label htmlFor="industry" className="block mb-2">Industry</label>
                            <select
                                id="industry"
                                name="industry"
                                className="w-full border border-gray-300"
                                value={formData.industry}
                                onChange={handleChange}
                            >
                                <option value="">Industry</option>
                                {industry.map((ind, idx) => (
                                    <option key={idx} value={ind.name}>
                                        {ind}
                                    </option>
                                ))}
                            </select>
                            {errors.industry && <span className="text-red-500 text-sm">{errors.industry}</span>}

                        </div>
                        <div>
                            <label className="block mb-2">Year of Establishment</label>
                            <input
                                type="text"
                                name="year_of_establishment"
                                className="w-full border border-gray-300"
                                placeholder="YYYY/MM/DD"
                                value={formData.year_of_establishment}
                                onChange={handleChange}
                            />
                            {errors.year_of_establishment && <span className="text-red-500 text-sm">{errors.year_of_establishment}</span>}

                        </div>
                    </div>

                   <div>
                   <label>About Us</label>
                    <textarea
                        id="about_us"
                        name="about_us"
                        rows="4"
                        className="block p-2.5 w-full border border-gray-300"
                        placeholder="Write your thoughts here..."
                        value={formData.about_us}
                        onChange={handleChange}
                    ></textarea>
                            {errors.about_us && <span className="text-red-500 text-sm">{errors.about_us}</span>}
                   </div>

                    <button
                        type="button"
                        className="theme-btn bordered-btn py-2.5 mt-3"
                        onClick={handleSubmit}
                    >
                        <span className="color-text">{isProfileExists ? 'Update' : 'Submit'}</span>
                    </button>
                </form>
            </div>
        </>
    );
};

export default CompanyInfo;
