import React, { useState, useEffect } from "react";
import { getLocation } from "../../../utils/candidate.api/candidate.api.services";

const JobDetailsForm = ({ formData, setFormData }) => {
    const [location, setLocation] = useState([]);

    const noticePeriod = [
        { name: "Immediate/15 days" },
        { name: "30 days" },
        { name: "60 days" },
        { name: "90 days or more" },
        { name: "Serving notice period" },

    ];

    const fetchLocation = async () => {
        try {
            let response = await getLocation();
            setLocation(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchLocation();
    }, []);

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <>
            <div className="form-theme mt-8">
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                        <label htmlFor="job_title" className="block mb-2">Job Title</label>
                        <input
                            type="text"
                            name="job_title"
                            className="w-full border border-gray-300"
                            placeholder="UI/UX Designer"
                            value={formData.job_title || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="job_type" className="block mb-2">Job Type</label>
                        <select
                            id="job_type"
                            name="job_type"
                            className="w-full border border-gray-300"
                            value={formData.job_type || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Job Type</option>
                            <option value="Full-time">Full-time</option>
                            <option value="Internship">Internship</option>
                            <option value="Contractual">Contractual</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="job_mode" className="block mb-2">Job Mode</label>
                        <select
                            id="job_mode"
                            name="job_mode"
                            className="w-full border border-gray-300"
                            value={formData.job_mode || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Job Mode</option>
                            <option value="Hybrid">Hybrid</option>
                            <option value="Remote">Remote</option>
                            <option value="On-site">On-site</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="notice_period" className="block mb-2">Notice Period</label>
                        <select
                            id="notice_period"
                            name="notice_period"
                            className="w-full border border-gray-300"
                            value={formData.notice_period || ''}
                            onChange={handleInputChange}
                        >
                            {noticePeriod.map((items) => (
                                <option key={items.name} value={items.name}>{items.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="location" className="block mb-2">Location</label>
                        <select
                            id="location"
                            name="location"
                            className="w-full border border-gray-300"
                            value={formData.location || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Location</option>
                            {location.map((item) => (
                                <option key={item} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="expected_salary" className="block mb-2">Work Experience</label>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                            <div>
                                <input
                                    type="number"
                                    name="expected_salary_min"
                                    className="w-full border border-gray-300"
                                    placeholder="Min."
                                    // value={formData.expected_salary_min || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="number"
                                    name="expected_salary_max"
                                    className="w-full border border-gray-300"
                                    placeholder="Max."
                                    // value={formData.expected_salary_max || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="expected_salary" className="block mb-2">Expected Salary | Yearly</label>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                            <div>
                                <input
                                    type="number"
                                    name="expected_salary_min"
                                    className="w-full border border-gray-300"
                                    placeholder="Min."
                                    value={formData.expected_salary_min || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="number"
                                    name="expected_salary_max"
                                    className="w-full border border-gray-300"
                                    placeholder="Max."
                                    value={formData.expected_salary_max || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobDetailsForm;
