import axios from "axios";
import config from "./config";

const client = axios.create({});

client.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const errorResponse = JSON.parse(JSON.stringify(error));
        return Promise.reject(error);
    }
);

const axiosClient = (
    endpoint,
    payload = {},
    method = "get",
    headers = {}
) => {
    let axiosConfig = {
        method: method.toLowerCase(),
    };
    if (
        endpoint !== "login" &&
        endpoint !== "verifyOtp"
    ) {
        axiosConfig.headers = headers;
    }
    if (method === "get" || method === "GET") {
        axiosConfig.params = payload;
    } else {
        axiosConfig.data = payload;
    }
    return client(`${config.apiUrl}${endpoint}`, axiosConfig);
};
export default axiosClient;