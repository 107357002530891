
import React from 'react';
import { Box } from '@mui/material';
import DrawerHeader from '../dashboard/DrawerHeader';

export default function ConfigurationStages() {
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent" >
                <DrawerHeader />
                <div className='flex items-center justify-between mt-4'>
                    <div>
                        <h2 className='heading2'>Configure your own hiring stages </h2>
                        <p>Here’s the template you can edit and add new stages</p>
                    </div>
                    <button type="button" className="theme-btn  text-white">+ Add Hiring Stages</button>
                </div>
                <div className='stages mt-3'>
                    <div className='stage-item'>
                        <div className='circle'></div>
                        <h3>Stage 1</h3>
                        <input value="Screening"/>
                        <img src='images/icons/delete.svg' className='mr-2'/>
                        <img src='images/icons/edit.svg'/>
                    </div>
                    <div className='stage-item'>
                        <div className='circle'></div>
                        <h3>Stage 2</h3>
                        <input value="Tech round 1"/>
                        <img src='images/icons/delete.svg' className='mr-2'/>
                        <img src='images/icons/edit.svg'/>
                    </div>
                    <div className='stage-item'>
                        <div className='circle'></div>
                        <h3>Stage 3</h3>
                        <input value="HR round "/>
                        <img src='images/icons/delete.svg' className='mr-2'/>
                        <img src='images/icons/edit.svg'/>
                    </div>
                    <div className='stage-item'>
                        <div className='circle'></div>
                        <h3>Stage 4</h3>
                        <input value="Rejected "/>
                    </div>
                    <div className='stage-item after-none'>
                        <div className='circle'></div>
                        <h3>Stage 5</h3>
                        <input value="Selected "/>
                    </div>
                </div>
                <button type="button" className="theme-btn bordered-btn py-2.5 mt-3 ml-auto flex">
                        <span className="color-text">Save Changes</span>
                    </button>
            </Box>
        </>
    );
}
