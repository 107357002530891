import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import config from '../config/config';

const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useSelector(state => !!state.auth.Auth);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 100);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Or some spinner
  }

  return isAuthenticated ? children : <Navigate to={`${config.baseUrl}login`} />;
};

export default ProtectedRoute;
