import React from "react";
import { Link } from "react-router-dom";
import config from "../config/config";

const Header = () => {
    return (
        <header className="py-4 mb-3">
            <div className="container mx-auto p-3">
                <div className="flex justify-between items-center">
                    <Link to={`${config.baseUrl}`} className="text-3xl font-bold">
                        <h1 className="logo">
                            <span className="color-text">Hirree</span>
                        </h1>
                    </Link>
                    <div className="flex justify-end items-center">
                        <div className="authen flex space-x-4">
                            <Link to={`${config.baseUrl}login`}>
                                <button type="button" className="theme-btn bordered-btn ">
                                    <span className="color-text">Login</span>
                                </button>
                            </Link>
                            <Link to={`${config.baseUrl}signup`}>
                                <button type="button" className="theme-btn text-white">
                                    Sign Up
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
