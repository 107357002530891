import React from "react";
import AppBar from "../../Appbar";
import Drawer from "../../Drawer";
import { Box, CssBaseline } from '@mui/material';
import SettingContent from "./setting";

const Setting = () => {
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar />
                <Drawer />
                <SettingContent />
            </Box>
        </>
    )
}
export default Setting;