import React,{useEffect} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import JobDetailsFrom from './JobDetailsFrom';
import JobDescriptionForm from './jonDescriptionForm';
import JobPreviewForm from './jobPreviewForm';
import axios from 'axios';
import config from '../../../config/config';
import { useSelector } from 'react-redux';
import {toast,Toaster} from 'react-hot-toast';
import CloseIcon from '@mui/icons-material/Close';


const steps = ['Job Details', 'Job Description', 'Job Preview'];

export default function PostJobForm({handleCloseFrom}) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const loginData = useSelector((state) => state);

    const [formData, setFormData] = React.useState({

        job_title: "",
        job_type: "",
        job_mode: "",
        location: "",
        work_experience: "",
        notice_period: "",
        expected_salary_min: "",
        expected_salary_max: "",
        job_description: "",
        skill_set: [],
    });

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
        setFormData({
         job_title: "",
        job_type: "",
        job_mode: "",
        location: "",
        work_experience: "",
        notice_period: "",
        expected_salary_min: "",
        expected_salary_max: "",
        job_description: "",
        skill_set: [],
        });
    };

    const handleFinish = async (e) => {
        // Make API call with formData
        e.preventDefault()
        try {
            const response = await axios.post(`${config.apiUrl}employer/jobs`, formData, {
                headers: {
                    'Authorization': `Bearer ${loginData.auth.Authn}`,
                },
            });
            if(response.status == 201){
                toast.success(response.data.message)
            }
            console.log(response)
        }
        catch (error) {
            console.log(error)
            if(error.response && error.response.data){
                toast.error("Empty field")
            }
        }
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return <JobDetailsFrom formData={formData} setFormData={setFormData} />;
            case 1:
                return <JobDescriptionForm formData={formData} setFormData={setFormData} />;
            case 2:
                return <JobPreviewForm formData={formData} setFormData={setFormData} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Toaster/>
            <div className='flex justify-end'><CloseIcon onClick={handleCloseFrom} style={{cursor:"pointer"}}/></div>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {renderStepContent(activeStep)}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )} */}
                         {/* <button type='button' className='mr-2 bordered-btn theme-btn' onClick={handleCloseFrom}><span className="color-text">Close</span></button> */}
                        <button type='button' onClick={activeStep === steps.length - 1 ? handleFinish : handleNext} className='theme-btn text-white'>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}
