import React, { Suspense } from "react";
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ApplicationContent from "./applicationContent";
// const ProfilePictureComponent = React.lazy(() => import('./ProfilePictureComponent'));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StatusTabs = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <h2 className="menu-heading">My Applications</h2>
            <Box sx={{ width: '100%' }} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="hirree-tabs">
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                        <Tab label="Applied" {...a11yProps(0)} />
                        <Tab label="In Review" {...a11yProps(1)} />
                        <Tab label="Shortlisted" {...a11yProps(2)} />
                        <Tab label="In-Progress" {...a11yProps(3)} />
                        <Tab label="Offered" {...a11yProps(4)} />
                        <Tab label="Hired" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <ApplicationContent />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    {/* <Resume/> */}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    {/* <Prefrence/> */}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <Suspense fallback={<div>Loading...</div>}>
                        {/* <AccountSetting/> */}
                    </Suspense>
                </CustomTabPanel>
            </Box>
        </>
    )
}
export default StatusTabs;