import React from "react";

export default function JobDetails() {
    return (
        <>
            <div className="p-10 jb-details" style={{ backgroundColor: "#E9E9E9", height: "100%" }}>
                <div className="white-box">
                    <div className='flex items-center'>
                        <img src="images/slack.svg" />
                        <div className="ml-2">
                            <h4>Sr. UI/UX Designer</h4>
                            <p>Slack Mumbai , India  , 3 lakh CTC</p>
                        </div>
                        <div className="ml-auto items-center flex">
                            <button type='button' className='theme-btn text-white text-sm mr-3'>Apply Now</button>
                            <button type='button'><img src='images/icons/share.svg' className='mr-5' /></button>
                            <button type='button'><img src='images/icons/save.svg' /></button>
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-wrap">
                    <div className="md:w-8/12">
                        <div className="white-box job-content">
                            <h3>About this role</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Aliquet vitae eget egestas sed. Dui elementum fermentum ridiculus elementum enim. Sociis phasellus donec cras faucibus turpis est aliquet. In bibendum blandit condimentum tristique sit non vulputate mi. Sit eget enim diam mauris praesent varius. Justo mi morbi tempus diam nec platea amet pulvinar. Nibh elit est cursus mauris suspendisse amet id et.</p>
                            <h3>Responsibilities</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Aliquet vitae eget egestas sed. Dui elementum fermentum ridiculus elementum enim. Sociis phasellus donec cras faucibus turpis est aliquet. In bibendum blandit condimentum tristique sit non vulputate mi. Sit eget enim diam mauris praesent varius. Justo mi morbi tempus diam nec platea amet pulvinar. Nibh elit est cursus mauris suspendisse amet id et.</p>
                            <h3>Requirements</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Aliquet vitae eget egestas sed. Dui elementum fermentum ridiculus elementum enim. Sociis phasellus donec cras faucibus turpis est aliquet. In bibendum blandit condimentum tristique sit non vulputate mi. Sit eget enim diam mauris praesent varius. Justo mi morbi tempus diam nec platea amet pulvinar. Nibh elit est cursus mauris suspendisse amet id et.</p>
                        </div>
                    </div>
                    <div className="md:w-4/12"></div>
                </div> */}
            </div>
        </>
    )
}