import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import UploaFile from '../../resume/uploadResume';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import config from '../../../../config/config';

const Resume = () => {
    const loginData = useSelector((state) => state.auth.Auth);
    const Cv_Name = useSelector((state) => state.parsedCv.parseCvData.filename);
    const fileId = useSelector((state) => state.parsedCv.parseCvData.file_id);
    const [fileList, setFileList] = useState([]);
    const [error, setError] = useState(null);
    const [openDelet, setOpenDelet] = useState(null)
    const [loading, setLoading] = useState(true);


    const fetchAllResumes = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}candidate/get_all_cv`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                }
            });
            // Handle data based on structure

            const resumes = Array.isArray(response.data) ? response.data : response.data.cv_files || [];
            setFileList(resumes.map(resume => ({
                id: resume.file_id,
                name: resume.filename,
                url: `${config.apiUrl}candidate/get_cv/${resume.file_id}`,
                defaultCV: resume.default_cv
            })));
        } catch (error) {
            console.error(`Error fetching all resumes: ${error}`);
            setError(error);
        }
    };
    useEffect(() => {
        if (!fileId) return;
        fetchAllResumes();
    }, [loginData, fileId, Cv_Name]);

    const openPdfInNewTab = async (url) => {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                    'Content-Type': 'application/pdf',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const blob = await response.blob();
            const pdfUrl = URL.createObjectURL(blob);
            window.open(pdfUrl, '_blank');
        } catch (error) {
            console.error(`Error opening PDF: ${error}`);
        }
    };

    const DeletCv = async (fileId) => {
        try {
            
            await axios.delete(`${config.apiUrl}candidate/delete_cv/${fileId}`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                }
            });
            fetchAllResumes();
        } catch (error) {
            console.log(error)
        }
    }
    const handleDropdownToggle = (id) => {
        setOpenDelet(prev => prev === id ? null : id);
    };

    // if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading files: {error.message}</p>;

    return (
        <>
            <div>
                {fileList.length > 0 && (
                    <>
                        {fileList.map((item) => (
                            <div key={item.id} className="file-item flex justify-between mb-3 relative" style={{ width: "50%" }}>
                                <button onClick={() => openPdfInNewTab(item.url)} className="file-btn flex-grow">
                                    {item.name}
                                </button>
                                {item.defaultCV === false ?
                                    <button onClick={() => handleDropdownToggle(item.id)} className="delete-btn absolute right-2 top-2">
                                        <MoreVertIcon fontSize='small' />
                                    </button> : ""
                                }

                                {openDelet === item.id && (
                                    <div className='absolute top-5 right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                        <div className='p-2 flex items-center'>
                                            <button className='flex items-center' onClick={() => DeletCv(item.id)}><img src='images/icons/delet.svg' className='flex' />Delete</button>
                                        </div>
                                    </div>
                                )}

                            </div>
                        ))}

                    </>
                )}
            </div>

            <UploaFile onUploadSuccess={fetchAllResumes} />
        </>
    );
};

export default Resume;
