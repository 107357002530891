import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from '../Appbar';
import Drawer from '../Drawer';
import DrawerHeader from '../DrawerHeader';
import StatusTabs from './statusTabs';

export default function MyApplications() {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar />
            <Drawer />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent">
                <DrawerHeader />
                <StatusTabs />
            </Box>
        </Box>
    );
}
