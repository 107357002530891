import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BorderAllRounded } from '@mui/icons-material';
import PostJobForm from './postFrom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius:"10px"
};

export default function PostJobModal({openForm,setOpenForm}) {

  const handleCloseFrom = () =>{
    setOpenForm(false)
  }
  return (
    <div>
      <Modal
        open={openForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <PostJobForm handleCloseFrom={handleCloseFrom}/>
        </Box>
      </Modal>
    </div>
  );
}
