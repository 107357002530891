import { TOGGLE_DRAWER,Auth,LOGOUT,LOGIN_SUCCESS} from "../actionTypes";

const initialState = {
  drawerOpen: false,
  Auth:'',
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        drawerOpen: action.payload,
      };
      case Auth:
      return {
        ...state,
        Auth: action.payload,
      };
      case LOGIN_SUCCESS:
        return {
          ...state,
          Auth: action.payload, // Store the authentication data
        };
      case LOGOUT:
        return {
          ...state,
          Auth: '', 
        };
    default:
      return state;
  }
};

export default rootReducer;
  