
import React, { useState } from "react";
import WithPassword from "./withPassword";
import WithOTP from "./withOtp";
import LoginFooter from "../loginFooter";

const EmployerLogin = () => {
  const [loginMethod, setLoginMethod] = useState('password');

  const switchToOtp = () => setLoginMethod('otp');
  const switchToPassword = () => setLoginMethod('password');

  return (
    <div className="p-5">
      {loginMethod === 'password' ? (
        <WithPassword switchToOtp={switchToOtp} />
      ) : (
        <WithOTP switchToPassword={switchToPassword} />
      )}
      <LoginFooter />
    </div>
  );
};

export default EmployerLogin;