import React from "react";

const Details  = () =>{
    return(
        <>
        <div>
            <div className="design-box">
                <div className="heading">
                    <h2>Detail information</h2>
                </div>
                <div className="box-content">
                    <div className="grid grid-cols-2 gap-2">
                            <div className="box-details">
                                <div className="mb-2">
                                    <label>Full Name</label>
                                    <span>Charlie Kristen</span>
                                </div>
                                <div className="mb-2">
                                    <label>Notice Period</label>
                                    <span>Immediate Joiner</span>
                                </div>
                                <div className="mb-2">
                                    <label>Current Salary</label>
                                    <span>2-4 lakh per annum</span>
                                </div>
                            </div>
                            <div className="box-details">
                                <div className="mb-2">
                                    <label>Experience</label>
                                    <span>4 Years 8 months</span>
                                </div>
                                <div className="mb-2">
                                    <label>Highest Qualification</label>
                                    <span>B.Des</span>
                                </div>
                                <div className="mb-2">
                                    <label>Expected Salary</label>
                                    <span>6- 8 lakh per annum</span>
                                </div>
                            </div>
                    </div>
                    <div className="select-btn">
                        <label>Skill set</label>
                        <button>UI Design</button>
                        <button>UI Design</button>
                        <button>UI Design</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Details;