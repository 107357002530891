import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import config from '../../../config/config';

export default function SocialMedia() {
    const loginData = useSelector((state) => state.auth.Auth);

    // State to hold company_id and form data
    const [companyId, setCompanyId] = useState(null);
    const [formData, setFormData] = useState({
        linkedin: "",
        facebook: "",
    });

    // Fetch company info including company_id on component mount
    useEffect(() => {
        const fetchCompanyInfo = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}employer/company-info`, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    }
                });
                
                if (response.data) {
                    setCompanyId(response.data.id); 
                }
            } catch (error) {
                console.error("Error fetching company info:", error);
            }
        };

        fetchCompanyInfo();
    }, [loginData.access_token]);

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle form submission
    const handleSubmit = async () => {
        const payload = {
            company_id: companyId,  // Use the fetched company_id
            linkedin: formData.linkedin,
            facebook: formData.facebook,
        };

        try {
            const response = await axios.post(`${config.apiUrl}employer/social-media-profile`, payload, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 201) {
                toast.success("Social media profiles updated successfully!");
            }
        } catch (error) {
            toast.error("Failed to update social media profiles. Please try again.");
            console.error("Error updating social media profiles:", error);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}employer/social-media-profile`, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    },
                });
                if (response.data) {
                    const info = response.data
                    setFormData({
                        linkedin: info.linkedin,
                        facebook: info.facebook,
                    })
                }
               
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [loginData.access_token]);
    return (
        <div>
            <Toaster/>
            <form className="mt-10 form-theme" onSubmit={e => { e.preventDefault(); handleSubmit(); }}>
                <div className="grid gap-6 mb-6 md:grid-cols-1">
                    <div>
                        <label className="block mb-2">
                            <img src='images/icons/linkedIn.svg' className='inline-block mr-2 h-5 w-5' alt="LinkedIn" />LinkedIn
                        </label>
                        <input
                            type="text"
                            name="linkedin"
                            value={formData.linkedin}
                            onChange={handleChange}
                            className="w-full border border-gray-300"
                            placeholder="https://linkedin.com/yourprofile"
                        />
                    </div>
                    <div>
                        <label className="block mb-2">
                            <img src='images/icons/facebook.svg' className='inline-block mr-2 h-5 w-5' alt="Facebook" />Facebook
                        </label>
                        <input
                            type="text"
                            name="facebook"
                            value={formData.facebook}
                            onChange={handleChange}
                            className="w-full border border-gray-300"
                            placeholder="https://facebook.com/yourprofile"
                        />
                    </div>
                 
                </div>
                <button type="submit" className="theme-btn bordered-btn py-2.5">
                    <span className="color-text">Next</span>
                </button>
            </form>
        </div>
    );
}
